<script setup lang="ts">
import { ref } from 'vue';

import { useI18n } from 'vue-i18n';

import { icons } from '@/helpers/icons';

const i18n = useI18n();
const active = ref(false);

/**
 * Set Locale.
 * @param locale
 */
function setLocale(locale: string) {
    i18n.locale.value = locale;

    localStorage.setItem('locale', locale);

    active.value = false;
}
</script>

<template>
    <div class="relative">
        <div
            @click="active = !active"
            class="flex cursor-pointer items-center space-x-2 rounded p-1 hover:bg-black/5"
        >
            <template v-if="i18n.locale.value == 'en'">
                <div class="inline-block overflow-hidden rounded-full">
                    <i v-html="icons.en"></i>
                </div>

                <div>EN</div>
            </template>

            <template v-if="i18n.locale.value == 'fo'">
                <div class="inline-block overflow-hidden rounded-full">
                    <i v-html="icons.fo"></i>
                </div>

                <div>FO</div>
            </template>

            <i
                v-html="icons.chevron"
                class="text-[9px]"
            ></i>
        </div>

        <div
            v-click-away="
                () => {
                    active = false;
                }
            "
            class="absolute top-full left-1/2 -translate-x-1/2 bg-white shadow-lg transition"
            :class="{ 'pointer-events-none -translate-y-2 opacity-0': !active }"
        >
            <a
                v-if="i18n.locale.value != 'en'"
                @click.prevent="setLocale('en')"
                href="#"
                class="flex items-center space-x-2 py-2 px-4 pr-8 hover:bg-black/5"
            >
                <div class="inline-block overflow-hidden rounded-full">
                    <i v-html="icons.en"></i>
                </div>

                <div>EN</div>
            </a>

            <a
                v-if="i18n.locale.value != 'fo'"
                @click.prevent="setLocale('fo')"
                href="#"
                class="flex items-center space-x-2 py-2 px-4 pr-8 hover:bg-black/5"
            >
                <div class="inline-block overflow-hidden rounded-full">
                    <i v-html="icons.fo"></i>
                </div>

                <div>FO</div>
            </a>
        </div>
    </div>
</template>
