import axios, { type AxiosRequestConfig, type AxiosResponse } from 'axios';
// import type { OrderProduct } from './order-line.model'; // TODO: Add OrderProduct type

import ResourceService from './_resource.service';

import store from '@/store';

import type Order from '@/models/order.model';

export type OrderStatus = 'Created' | 'In Progress' | 'Completed' | 'Delivered' | 'Cancelled' | 'Payment Failed' | 'Refunded' | 'Partially Refunded';

export type ListedOrder = {
    id: string;
    number: string;
    status: string;
    paid: boolean;
    total: string;
    has_delivery: boolean;
    created_at: string | Date;
    paymentStatuses: PaymentStatus[];
    // products: OrderProduct[]; // TODO: Add OrderProduct type
};

export type PaymentStatus = {
[x: string]: string;
    name: string;
    at: string;
    order_id: string;
    status_message: string;
    id: string;
};

export type OrderTotal = {
    total: string;
    skaffa_fee: {
        percentage: string;
        total: string;
    };
    orders_with_delivery_count: number;
};

export default class OrdersService extends ResourceService<Order> {
    public static url = `${import.meta.env.VITE_API_URL}/orders`;

    constructor() {
        super(axios, OrdersService.url);
    }

    /**
     * Get all
     * @param locationId
     * @param config
     * @returns
     */
    public static index(locationId: number, config?: AxiosRequestConfig): Promise<Order[]> {
        return new ResourceService<Order>(axios, `${import.meta.env.VITE_API_URL}/locations/${locationId}/orders`).index(config);
    }

    /**
     * Get all
     * @param locationId
     * @param config
     * @returns
     */
    public static indexOptimized(locationId: number, params: Record<string, any> = {}, config?: AxiosRequestConfig): Promise<Order[]> {
        const enhancedConfig = {
            ...(config || {}),
            params: {
                ...(config?.params || {}), // provide empty object as fallback
                ...(params || {}),
            },
        };

        return new ResourceService<Order>(axios, `${import.meta.env.VITE_API_URL}/locations/${locationId}/ordersfast`).index(enhancedConfig);
    }

    /**
     * Get one
     * @param id
     * @param config
     * @returns
     */
    public static show(id: string | number, config?: AxiosRequestConfig): Promise<Order> {
        return new ResourceService<Order>(axios, this.url).show(id, config);
    }

    /**
     * Gets a payment status for an order
     * @param id id for order
     * @returns 
     */
    // public static getPaymentStatus(id: string | number): Promise<PaymentStatus | null> {
    //     return axios
    //         .get(`${import.meta.env.VITE_API_URL}/orders/${id}/payment-status`)
    //         .then((response) => {
    //             return response.data as PaymentStatus; // Ensure the correct response type
    //         })
    //         .catch((error) => {
    //             console.error(error);
    //             return null;
    //         });
    // }
    public static async getPaymentStatus(id: string | number): Promise<PaymentStatus | null> {
        try {
            const response = await axios.get(`${import.meta.env.VITE_API_URL}/orders/${id}/payment-status`);
            const statuses: PaymentStatus[] = response.data;
            return statuses.length > 0 ? statuses[0] : null;
        } catch (error) {
            console.error('Error fetching payment status:', error);
            return null;
        }
    }

    /**
     * Set status
     * @param id
     * @param status
     * @param config
     * @returns
     */
    public static async setStatus(id: string, data: { status: OrderStatus; minutes?: number }, config?: AxiosRequestConfig): Promise<Order> {
        return axios
            .post(`${OrdersService.url}/${id}/status`, data, config)
            .then((response) => {
                const order = store.orders.find((order) => order.id == id);

                if (order) {
                    order.status = data.status;
                }

                return response.data;
            })
            .catch((error) => {
                console.log(error);
            });
    }

    /**
     * Complete order
     * @param id
     * @param productCategoryIds
     * @returns
     */
    public static async complete(id: string, productId: number, orderLineIds: string[], completed: boolean) {
        return axios
            .post(`${OrdersService.url}/${id}/complete`, {
                product_id: productId,
                order_line_ids: orderLineIds,
                completed,
            })
            .then((response) => {
                return response;
            })
            .catch((error) => {
                console.log(error);
            });
    }

    public static async indexForListView(locationId: number, params = {}): Promise<AxiosResponse<PaginatedResource<ListedOrder>> | null> {
        return axios
            .get(`${import.meta.env.VITE_API_URL}/locations/${locationId}/orders/list`, { params })
            .then((response) => {
                return response as any;
            })
            .catch((error) => {
                console.log(error);

                return null;
            });
    }

    public static async total(locationId: number, params = {}): Promise<AxiosResponse<OrderTotal> | null> {
        return axios
            .get(`${import.meta.env.VITE_API_URL}/locations/${locationId}/orders/total`, { params })
            .then((response) => {
                return response as any;
            })
            .catch((error) => {
                console.log(error);

                return null;
            });
    }

    public static async createPosOrder(data: {
        location_id: number;
        note: string;
        discount: {
            type: string;
            amount: string;
        };
        order_lines: any[];
        expected_price: number;
        paid: boolean;
    }) {
        return axios
            .post(`${OrdersService.url}/pos`, data)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                console.log(error);
            });
    }

    public static async meta(id: string, meta: any) {
        return axios
            .patch(`${OrdersService.url}/${id}/meta`, meta)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                console.log(error);
            });
    }
}
