import axios, { type AxiosRequestConfig } from 'axios';

import ResourceService from './_resource.service';

import type Ingredient from '@/models/ingredient.model';

import type CreateIngredientRequest from '@/requests/create-ingredient.request';
import type UpdateIngredientRequest from '@/requests/update-ingredient.request';

export default class IngredientsService extends ResourceService<Ingredient> {
    public static url = `${import.meta.env.VITE_API_URL}/ingredients`;

    constructor() {
        super(axios, IngredientsService.url);
    }

    /**
     * Gets all ingredients
     *
     * @param config AxiosRequestConfig
     * @returns Ingredient[]
     */
    public static index(config?: AxiosRequestConfig): Promise<Ingredient[]> {
        return new ResourceService<Ingredient>(axios, IngredientsService.url).index(config);
    }

    /**
     * Gets a single ingredient with the given id
     *
     * @param id Ingredient id
     * @param config AxiosRequestConfig
     * @returns Ingredient
     */
    public static show(id: number | string, config?: AxiosRequestConfig): Promise<Ingredient> {
        return new ResourceService<Ingredient>(axios, IngredientsService.url).show(id, config);
    }

    /**
     * Creates a new ingredient
     *
     * @param createRequest CreateIngredientRequest
     * @param config AxiosRequestConfig
     * @returns Ingredient
     */
    public static store(createRequest: CreateIngredientRequest, config?: AxiosRequestConfig): Promise<Ingredient> {
        return new ResourceService<CreateIngredientRequest, Ingredient>(axios, IngredientsService.url).store(
            createRequest,
            config
        );
    }

    /**
     * Updates a ingredient with the given id
     *
     * @param id Ingredient id
     * @param updateRequest UpdateIngredientRequest
     * @param config AxiosRequestConfig
     * @returns Ingredient
     */
    public static update(
        id: number | string,
        updateRequest: UpdateIngredientRequest,
        config?: AxiosRequestConfig
    ): Promise<Ingredient> {
        return new ResourceService<UpdateIngredientRequest, Ingredient>(axios, IngredientsService.url).update(
            id,
            updateRequest,
            config
        );
    }

    /**
     * Deletes an ingredient with the given id
     *
     * @param id Ingredient id
     * @param config AxiosRequestConfig
     * @returns boolean
     */
    public static delete(id: number | string, config?: AxiosRequestConfig): Promise<boolean> {
        return new ResourceService<Ingredient>(axios, IngredientsService.url).delete(id, config);
    }
}
