import axios, { AxiosError, type AxiosRequestConfig, type AxiosResponse } from 'axios';

import { error as showErrorMessage } from '@/helpers/error';

import ResourceService from './_resource.service';

import type Restaurant from '@/models/restaurant.model';
import type Location from '@/models/location.model';

import type CreateRestaurantRequest from '@/requests/create-restaurant.request';
import type UpdateRestaurantRequest from '@/requests/update-restaurant.request';
import type Menu from '@/models/menu.model';
import type Product from '@/models/product.model';
import type Ingredient from '@/models/ingredient.model';
import type { Attribute } from '@/models/variant.model';
import type ProductCategory from '@/models/product-category.model';

import type FoodType from '@/models/food.type';
import type User from '@/models/user.model';
import type { AxiosErrorData } from '@/models/error.model';

export default class RestaurantsService extends ResourceService<Restaurant> {
    public static url = `${import.meta.env.VITE_API_URL}/restaurants`;

    constructor() {
        super(axios, RestaurantsService.url);
    }

    /**
     * Gets all restaurants
     *
     * @param config AxiosRequestConfig
     * @returns Restaurant[]
     */
    public static index(config?: AxiosRequestConfig): Promise<Restaurant[]> {
        return axios
            .get(`${import.meta.env.VITE_API_URL}/all-restaurants`, config)
            .then((response: AxiosResponse) => {
                return response.data;
            })
            .catch((error: AxiosError) => {
                const errorData: AxiosErrorData = error.response?.data as AxiosErrorData;

                showErrorMessage({
                    text: errorData?.message,
                });
            });
    }

    /**
     * Gets a single restaurant with the given id
     *
     * @param id Restaurant id
     * @param config AxiosRequestConfig
     * @returns Restaurant
     */
    public static show(id: number | string, config?: AxiosRequestConfig): Promise<Restaurant> {
        return new ResourceService<Restaurant>(axios, RestaurantsService.url).show(id, config);
    }

    /**
     * Creates a new restaurant
     *
     * @param createRequest CreateRestaurantRequest
     * @param config AxiosRequestConfig
     * @returns Restaurant
     */
    public static async store(
        createRequest: CreateRestaurantRequest,
        config?: AxiosRequestConfig
    ): Promise<Restaurant> {
        const formData = new FormData();

        formData.append('name', createRequest.name ?? '');

        if (createRequest.address) {
            formData.append('address', createRequest.address ?? '');
        }

        if (createRequest.city) {
            formData.append('city', createRequest.city ?? '');
        }

        if (createRequest.zip) {
            formData.append('zip', createRequest.zip.toString());
        }

        if (createRequest.phone) {
            formData.append('phone', createRequest.phone.toString());
        }

        if (createRequest.category_ids) {
            formData.append('category_ids', JSON.stringify(createRequest.category_ids));
        }

        // if (createRequest.reepay_private_key) {
        //     formData.append('reepay_private_key', createRequest.reepay_private_key);
        // }

        // if (createRequest.reepay_webhook_secret) {
        //     formData.append('reepay_webhook_secret', createRequest.reepay_webhook_secret);
        // }

        if (createRequest.logo) {
            const response = await fetch(createRequest.logo);

            formData.append('logo', await response.blob());
        }

        if (createRequest.cover_image) {
            const response = await fetch(createRequest.cover_image);

            formData.append('cover_image', await response.blob());
        }

        return axios
            .post(`${RestaurantsService.url}`, formData, config)
            .then((response: AxiosResponse) => {
                return response.data;
            })
            .catch((error: AxiosError) => {
                const errorData: AxiosErrorData = error.response?.data as AxiosErrorData;

                showErrorMessage({
                    text: errorData?.message,
                });
            });
    }

    /**
     * Updates a restaurant with the given id
     *
     * @param id Restaurant id
     * @param updateRequest UpdateRestaurantRequest
     * @param config AxiosRequestConfig
     * @returns Restaurant
     */
    public static async update(
        id: number | string,
        updateRequest: UpdateRestaurantRequest,
        config?: AxiosRequestConfig
    ): Promise<Restaurant> {
        const formData = new FormData();

        formData.append('name', updateRequest.name ?? '');

        if (updateRequest.address) {
            formData.append('address', updateRequest.address ?? '');
        }

        if (updateRequest.city) {
            formData.append('city', updateRequest.city ?? '');
        }

        if (updateRequest.zip) {
            formData.append('zip', updateRequest.zip.toString());
        }

        if (updateRequest.phone) {
            formData.append('phone', updateRequest.phone.toString());
        }

        if (updateRequest.category_ids) {
            formData.append('category_ids', JSON.stringify(updateRequest.category_ids));
        }

        // if (updateRequest.reepay_private_key) {
        //     formData.append('reepay_private_key', updateRequest.reepay_private_key);
        // }

        // if (updateRequest.reepay_webhook_secret) {
        //     formData.append('reepay_webhook_secret', updateRequest.reepay_webhook_secret);
        // }

        if (updateRequest.logo) {
            const response = await fetch(updateRequest.logo);

            formData.append('logo', await response.blob());
        }

        if (updateRequest.cover_image) {
            const response = await fetch(updateRequest.cover_image);

            formData.append('cover_image', await response.blob());
        }

        formData.append('_method', 'PATCH');

        return axios
            .post(`${RestaurantsService.url}/${id}`, formData, config)
            .then((response: AxiosResponse) => {
                return response.data;
            })
            .catch((error: AxiosError) => {
                const errorData: AxiosErrorData = error.response?.data as AxiosErrorData;

                showErrorMessage({
                    text: errorData?.message,
                });
            });
    }

    /**
     * Deletes a restaurant with the given id
     *
     * @param id Restaurant id
     * @param config AxiosRequestConfig
     * @returns boolean
     */
    public static delete(id: number | string, config?: AxiosRequestConfig): Promise<boolean> {
        return new ResourceService<Restaurant>(axios, RestaurantsService.url).delete(id, config);
    }

    /**
     * Get menus
     * @param id
     * @param config
     * @returns
     */
    public static async setActive(id: number | string, active: boolean, config?: AxiosRequestConfig): Promise<Menu[]> {
        console.log(active);

        return axios
            .patch(`${RestaurantsService.url}/${id}`, { active }, config)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                console.log(error);
            });
    }

    /**
     * Get locations
     * @param id
     * @param config
     * @returns
     */
    public static async locations(id: number | string, config?: AxiosRequestConfig): Promise<Location[]> {
        return axios
            .get(`${RestaurantsService.url}/${id}/locations`, config)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                showErrorMessage({
                    text: error?.response?.data?.message,
                });
            });
    }

    /**
     * Get menus
     * @param id
     * @param config
     * @returns
     */
    public static async menus(id: number | string, config?: AxiosRequestConfig): Promise<Menu[]> {
        return axios
            .get(`${RestaurantsService.url}/${id}/menus`, config)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                console.log(error);
            });
    }

    /**
     *
     * @param id Get products
     * @param type
     * @param config
     * @returns
     */
    public static async products(
        id: number | string,
        type?: FoodType,
        config?: AxiosRequestConfig
    ): Promise<Product[]> {
        return axios
            .get(`${RestaurantsService.url}/${id}/products/${type ? type.toLowerCase() : ''}`, config)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                console.log(error);
            });
    }

    /**
     * Get ingredients
     * @param id
     * @param type
     * @param config
     * @returns
     */
    public static async ingredients(
        id: number | string,
        type: FoodType,
        config?: AxiosRequestConfig
    ): Promise<Ingredient[]> {
        return axios
            .get(`${RestaurantsService.url}/${id}/ingredients/${type.toLowerCase()}`, config)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                console.log(error);
            });
    }

    /**
     * Get ingredient groups
     * @param id
     * @param config
     * @returns
     */
    public static async ingredientGroups(id: number | string, config?: AxiosRequestConfig): Promise<Ingredient[]> {
        return axios
            .get(`${RestaurantsService.url}/${id}/ingredient-groups`, config)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                console.log(error);
            });
    }

    /**
     * Get attributes
     * @param id
     * @param config
     * @returns
     */
    public static async attributes(id: number | string, config?: AxiosRequestConfig): Promise<Attribute[]> {
        return axios
            .get(`${RestaurantsService.url}/${id}/attributes`, config)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                console.log(error);
            });
    }

    /**
     * Get product categories
     * @param id
     * @param type
     * @param config
     * @returns
     */
    public static async productCategories(
        id: number | string,
        type: FoodType,
        config?: AxiosRequestConfig
    ): Promise<ProductCategory[]> {
        return axios
            .get(`${RestaurantsService.url}/${id}/product-categories/${type.toLowerCase()}`, config)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                console.log(error);
            });
    }

    /**
     * Get product categories
     * @param id
     * @param config
     * @returns
     */
    public static async allProductCategories(
        id: number | string,
        config?: AxiosRequestConfig
    ): Promise<ProductCategory[]> {
        return axios
            .get(`${RestaurantsService.url}/${id}/product-categories`, config)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                console.log(error);
            });
    }

    /**
     * Update order menus
     * @param id
     * @param menu_ids
     * @param config
     * @returns
     */
    public static async orderMenus(
        id: number | string,
        menu_ids: number[],
        config?: AxiosRequestConfig
    ): Promise<Menu[]> {
        return axios
            .patch(`${RestaurantsService.url}/${id}/order-menus`, { menu_ids }, config)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                console.log(error);
            });
    }

    /**
     * Get users
     * @param id
     * @returns
     */
    public static async users(id: number | string): Promise<User[]> {
        return axios
            .get(`${RestaurantsService.url}/${id}/users`)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                console.log(error);
            });
    }

    /**
     * Get invitations
     * @param id
     * @returns
     */
    public static async invitations(id: number | string): Promise<any[]> {
        return axios
            .get(`${RestaurantsService.url}/${id}/invitations`)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                console.log(error);
            });
    }
}
