import AttributesService from '@/services/attributes.service';
import IngredientGroupsService from '@/services/ingredient-groups.service';
import IngredientsService from '@/services/ingredients.service';
import LocationsService from '@/services/locations.service';
import MenusService from '@/services/menus.service';
import OrdersService from '@/services/order.service';
import ProductCategoriesService from '@/services/product-categories.service';
import ProductsService from '@/services/products.service';
import RestaurantCategoriesService from '@/services/restaurant-categories.service';
import RestaurantsService from '@/services/restaurants.service';
import TerminalsService from '@/services/terminals.service';
import UsersService from '@/services/users.service';
import VibrantSpotsService from '@/services/vibrantspot.service';
import store from '@/store';

type ResourceName =
    | 'attributes'
    | 'ingredient-groups'
    | 'ingredients'
    | `location:${number}`
    | 'locations'
    | `menu:${number}`
    | 'menus'
    | `order:${number | string}`
    | 'orders'
    | `product-category:${number}`
    | 'product-categories'
    | `product:${number}`
    | 'products'
    | 'restaurant-categories'
    | `restaurant:${string}`
    | 'restaurants'
    | 'users'
    | `user:${string}`
    | `terminals`
    | `spots`;

export async function load(...resources: ResourceName[]): Promise<any[]> {
    store.loading = true;

    const promises = [];

    for (const resource of resources) {
        /**
         * Get a resource index
         * 'resource'
         */

        if (store.activeRestaurant) {
            if (resource == 'attributes') {
                promises.push(RestaurantsService.attributes(store.activeRestaurant.id));
                continue;
            }

            if (resource == 'ingredient-groups') {
                promises.push(RestaurantsService.ingredientGroups(store.activeRestaurant.id));
                continue;
            }

            if (resource == 'menus') {
                promises.push(RestaurantsService.menus(store.activeRestaurant.id));
                continue;
            }

            if (resource == 'orders' && store.activeLocation?.id) {
                promises.push(OrdersService.indexOptimized(store.activeLocation?.id));
                continue;
            }

            if (resource == 'product-categories') {
                promises.push(RestaurantsService.productCategories(store.activeRestaurant.id, store.type));
                continue;
            }

            if (resource == 'terminals' && store.activeLocation?.id && store.activeLocation?.vibrant_api_key) {
                promises.push(TerminalsService.index(store.activeLocation?.vibrant_api_key));
                continue;
            }

            if (resource == 'spots' && store.activeLocation?.id && store.activeLocation?.vibrant_api_key) {
                promises.push(VibrantSpotsService.index(store.activeLocation?.vibrant_api_key));
                continue;
            }

            if (resource == 'products') {
                promises.push(RestaurantsService.products(store.activeRestaurant.id, store.type));
                continue;
            }

            if (resource == 'ingredients') {
                promises.push(RestaurantsService.ingredients(store.activeRestaurant.id, store.type));
                continue;
            }

            if (resource == 'locations') {
                promises.push(RestaurantsService.locations(store.activeRestaurant.id));
                continue;
            }

            if (resource == 'users') {
                promises.push(RestaurantsService.users(store.activeRestaurant.id));
                continue;
            }
        }

        if (!store.activeRestaurant) {
            if (resource == 'attributes') {
                promises.push(AttributesService.index());
                continue;
            }

            if (resource == 'ingredient-groups') {
                promises.push(IngredientGroupsService.index());
                continue;
            }

            if (resource == 'ingredients') {
                promises.push(IngredientsService.index());
                continue;
            }

            if (resource == 'locations') {
                promises.push(LocationsService.index());
                continue;
            }

            if (resource == 'menus') {
                promises.push(MenusService.index());
                continue;
            }

            if (resource == 'product-categories') {
                promises.push(ProductCategoriesService.index());
                continue;
            }

            if (resource == 'products') {
                promises.push(ProductsService.index());
                continue;
            }

            if (resource == 'users') {
                promises.push(UsersService.index());
                continue;
            }
        }

        if (resource == 'restaurant-categories') {
            promises.push(RestaurantCategoriesService.index());
            continue;
        }

        if (resource == 'restaurants') {
            promises.push(RestaurantsService.index());
            continue;
        }

        /**
         * Get a single resource.
         * 'resource:{id}'
         */
        if (/^location:[0-9]*$/.test(resource)) {
            promises.push(LocationsService.show(resource.split(':')[1]));
            continue;
        }

        if (/^menu:[0-9]*$/.test(resource)) {
            promises.push(MenusService.show(resource.split(':')[1]));
            continue;
        }

        if (/^order:.*$/.test(resource)) {
            promises.push(OrdersService.show(resource.split(':')[1]));
            continue;
        }

        if (/^product-category:[0-9]*$/.test(resource)) {
            promises.push(ProductCategoriesService.show(resource.split(':')[1]));
            continue;
        }

        if (/^product:[0-9]*$/.test(resource)) {
            promises.push(ProductsService.show(resource.split(':')[1]));
            continue;
        }

        if (/^restaurant:.*$/.test(resource)) {
            promises.push(RestaurantsService.show(resource.split(':')[1]));
            continue;
        }

        if (/^user:.*$/.test(resource)) {
            promises.push(UsersService.show(resource.split(':')[1]));
            continue;
        }

        promises.push(Promise.resolve(null));
    }

    const responses = await Promise.all(promises);

    store.loading = false;

    return responses;
}
