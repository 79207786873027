import store from '@/store';

import i18n from '@/i18n';
import type Popup from '@/models/popup.model';

export interface Button {
    text: string;
    outlined?: boolean;
    callback?: () => void;
}

/**
 * Displays a Dialog Box with a Message and Buttons.
 * @param options
 */
export function popup(options: { title: string; text: any; buttons?: Button[]; translate?: boolean; type?: Popup['type']; position?: Popup['position'] }) {
    if (options.translate) {
        if (typeof options.title == 'string') {
            options.title = i18n.global.t(options.title);
        }

        if (typeof options.text == 'string') {
            options.text = i18n.global.t(options.text);
        }

        if (typeof options.title == 'object') {
            options.title = i18n.global.t(options.title[0], options.title[1]);
        }

        if (typeof options.text == 'object') {
            options.text = i18n.global.t(options.text[0], options.text[1]);
        }

        for (const button of options.buttons ?? []) {
            button.text = i18n.global.t(button.text);
        }
    }

    store.popup = {
        ...options,
        active: true,
    };
}

/**
 * Display a Confirm Dialog Box with a Message and 'Yes' & 'No' Buttons.
 * @param options
 */
export function popupConfirm(
    title: string,
    text: any,
    callback: () => void,
    type: Popup['type'] = 'default',
    translate = true,
    position: Popup['position'] = 'default'
) {
    popup({
        title,
        text,
        buttons: [
            {
                text: 'general.no',
                outlined: true,
            },
            {
                text: 'general.yes',
                callback,
            },
        ],
        translate,
        type,
        position,
    });
}
