import axios, { AxiosError, type AxiosRequestConfig, type AxiosResponse } from 'axios';

import { error as showErrorMessage } from '@/helpers/error';

import ResourceService from './_resource.service';

import type Restaurant from '@/models/restaurant.model';

import type CreateTerminalRequest from '@/requests/create-vibrantspot.request';
import type UpdateTerminalRequest from '@/requests/update-terminal.request';
import type VibrantSpotResponse from '@/responses/vibrantspot.response';

import type Terminal from '@/models/terminal.model';
import type VibrantSpot from '@/models/vibrantspot.model';

import type User from '@/models/user.model';
import type { AxiosErrorData } from '@/models/error.model';
import type { Update } from 'vite';
import type CreateVibrantSpotRequest from '@/requests/create-vibrantspot.request';
import type UpdateVibrantSpotRequest from '@/requests/update-vibrantspot.request';

export default class VibrantSpotsService extends ResourceService<VibrantSpot> {
    public static url = `${import.meta.env.VITE_API_URL}/vibrant`;

    constructor() {
        super(axios, VibrantSpotsService.url);
    }


    /**
     * Gets all Terminals
     *
     * @param config AxiosRequestConfig
     * @returns Terminal[]
     */
    public static index(vibrant_api_key: string, config?: AxiosRequestConfig): Promise<VibrantSpotResponse> {


        return axios
            .get(`${VibrantSpotsService.url}/get-spots`, {
                ...config,
                params: {
                    vibrant_api_key: vibrant_api_key
                }
            })
            .then((response: AxiosResponse) => {
                return response.data;
            })
            .catch((error: AxiosError) => {
                const errorData: AxiosErrorData = error.response?.data as AxiosErrorData;

                showErrorMessage({
                    text: errorData?.message,
                });
            });
    }


        /**
     * Gets a specific spot by id
     *
     * @param config AxiosRequestConfig
     * @returns VibrantSpot
     */
        public static getById(vibrant_api_key: string, id: string, config?: AxiosRequestConfig): Promise<VibrantSpotResponse> {


            return axios
                .get(`${VibrantSpotsService.url}/get-spot/${id}`, {
                    ...config,
                    params: {
                        vibrant_api_key: vibrant_api_key
                    }
                })
                .then((response: AxiosResponse) => {
                    return response.data;
                })
                .catch((error: AxiosError) => {
                    const errorData: AxiosErrorData = error.response?.data as AxiosErrorData;
    
                    showErrorMessage({
                        text: errorData?.message,
                    });
                });
        }


    /**
     * Deletes a spot
     *
     * @param config AxiosRequestConfig
     * @returns VibrantSpot[]
     */
        public static delete(vibrant_api_key: string, id: string, config?: AxiosRequestConfig): Promise<VibrantSpotResponse> {


            return axios
                .delete(`${VibrantSpotsService.url}/delete-spot/${id}`, {
                    ...config,
                    params: {
                        vibrant_api_key: vibrant_api_key
                    }
                })
                .then((response: AxiosResponse) => {
                    return response.data;
                })
                .catch((error: AxiosError) => {
                    const errorData: AxiosErrorData = error.response?.data as AxiosErrorData;
    
                    showErrorMessage({
                        text: errorData?.message,
                    });
                });
        }

/**
 * Creates a new spot
 *
 * @param createRequest CreateVibrantSpotRequest
 * @param config AxiosRequestConfig
 * @returns Spot
 */
public static async store(
    vibrant_api_key: string,
    createRequest: CreateVibrantSpotRequest,
    config?: AxiosRequestConfig
): Promise<VibrantSpot> {
    // Create an object with the request data
    const params = {
        vibrant_api_key: vibrant_api_key,
        name: createRequest.name
    };

    return axios
        .post(`${VibrantSpotsService.url}/create-spot`, null, {
            ...config,
            params,
        })
        .then((response: AxiosResponse) => {
            return response.data;
        })
        .catch((error: AxiosError) => {
            const errorData: AxiosErrorData = error.response?.data as AxiosErrorData;

            showErrorMessage({
                text: errorData?.message,
            });
        });
}



/**
 * Updates a Terminal with the given id
 *
 * @param id Terminal id
 * @param updateRequest UpdateTerminalRequest
 * @param config AxiosRequestConfig
 * @returns Terminal
 */
public static async update(
    vibrant_api_key: string,
    id: number | string,
    updateRequest: UpdateVibrantSpotRequest,
    config?: AxiosRequestConfig
): Promise<Terminal> {
    // Create an object with the request data
    const params = {
        vibrant_api_key: vibrant_api_key,
        name: updateRequest.name ?? ''
    };

    return axios
        .post(`${VibrantSpotsService.url}/update-spot/${id}`, null, {
            ...config,
            params,
        })
        .then((response: AxiosResponse) => {
            return response.data;
        })
        .catch((error: AxiosError) => {
            const errorData: AxiosErrorData = error.response?.data as AxiosErrorData;

            showErrorMessage({
                text: errorData?.message,
            });
        });
}




}
