<script setup lang="ts">
import { computed, watch } from 'vue';

import store from '@/store';

import { icons } from '@/helpers/icons';

/**
 * Computed
 */
const active = computed(() => {
    return !!store.success.active;
});

let timeout = setTimeout(() => {});

/**
 * Watchers
 */
watch(active, (active) => {
    clearTimeout(timeout);

    if (!active) {
        return;
    }
    if (store.success.keepOnScreen) {
        return;
    }

    timeout = setTimeout(() => {
        store.success.active = false;
    }, 3000);
});

/**
 * Functions
 */

function close() {
    store.success.active = false;
}
</script>

<template>
    <Teleport to="body">
        <div
            class="fixed left-[50%] bottom-0 z-50 w-full max-w-md translate-x-[-50%] bg-transparent px-4 pb-8 transition duration-300"
            :class="{ 'pointer-events-none opacity-0': !active }"
        >
            <div
                class="relative rounded bg-white transition"
                :class="{ 'translate-y-24': !active }"
            >
                <div class="flex items-center space-x-4 rounded bg-green-500/10 p-6 text-sm text-green-300">
                    <!-- Icon -->
                    <div
                        v-html="icons.checkmark"
                        class="flex-shrink-0"
                    ></div>

                    <!-- Text -->
                    <div class="pr-2">
                        {{
                            store.success?.text
                                ? store.success.textParams
                                    ? $t(store.success.text, [...store.success.textParams])
                                    : $t(store.success.text)
                                : ''
                        }}
                    </div>

                    <!-- Close Button -->
                    <a
                        href="#"
                        @click.prevent="close"
                        class="absolute top-2 right-2 grid h-6 w-6 place-items-center rounded-full transition hover:bg-dark-yellow-700/20"
                    >
                        <div v-html="icons.close"></div>
                    </a>
                </div>
            </div>
        </div>
    </Teleport>
</template>
