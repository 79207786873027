import type { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';

import store from '@/store';

export default function (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
    let guard: string = to.meta.guard as any;

    // If no guard is present, do nothing
    if (!guard) {
        return next();
    }

    // If guards are present and the user is not logged in, go to 'log in'
    if (!store.user) {
        return next({ name: 'login' });
    }

    if (guard == 'user') {
        return next();
    }

    if (guard == 'admin' && store.user.admin) {
        return next();
    }

    const restaurantId = to.params.restaurantId as string;
    const locationId = to.params.locationId as string;

    guard = guard.replace('{locationId}', locationId).replace('{restaurantId}', restaurantId);

    if (store.user.can(guard)) {
        return next();
    }

    if (store.activeRestaurant && store.activeLocation) {
        return next({
            name: 'dashboard',
            params: {
                restaurantId: store.activeRestaurant.id,
                locationId: store.activeLocation.id,
            },
        });
    }

    return next({ name: 'restaurants' });
}
