import axios, { type AxiosRequestConfig, type AxiosResponse } from 'axios';

import ResourceService from './_resource.service';

import { error as showErrorMessage } from '@/helpers/error';

import type RestaurantCategory from '@/models/restaurant-category.model';

import type CreateRestaurantCategoryRequest from '@/requests/create-restaurant-category.request';
import type UpdateRestaurantCategoryRequest from '@/requests/update-restaurant-category.request';
import type { AxiosErrorData } from '@/models/error.model';

export default class RestaurantCategoriesService extends ResourceService<RestaurantCategory> {
    public static url = `${import.meta.env.VITE_API_URL}/restaurant-categories`;

    constructor() {
        super(axios, RestaurantCategoriesService.url);
    }

    /**
     * Gets all restaurant categories
     *
     * @param config AxiosRequestConfig
     * @returns RestaurantCategory[]
     */
    public static async index(config?: AxiosRequestConfig): Promise<RestaurantCategory[]> {
        return new ResourceService<RestaurantCategory>(axios, RestaurantCategoriesService.url).index(config);
    }

    /**
     * Gets a single restaurant category with the given id
     *
     * @param id RestaurantCategory id
     * @param config AxiosRequestConfig
     * @returns RestaurantCategory
     */
    public static show(id: number | string, config?: AxiosRequestConfig): Promise<RestaurantCategory> {
        return new ResourceService<RestaurantCategory>(axios, RestaurantCategoriesService.url).show(id, config);
    }

    /**
     * Creates a new restaurant category
     *
     * @param createRequest CreateRestaurantCategoryRequest
     * @param config AxiosRequestConfig
     * @returns RestaurantCategory
     */
    public static async store(
        createRequest: CreateRestaurantCategoryRequest,
        config?: AxiosRequestConfig
    ): Promise<RestaurantCategory> {
        const formData = new FormData();

        // Name
        formData.append('name', JSON.stringify(createRequest.name));

        // Image
        const response = await fetch(createRequest.image);
        formData.append('image', await response.blob());

        return await axios
            .post(`${RestaurantCategoriesService.url}`, formData, config)
            .then((response: AxiosResponse) => {
                return response.data;
            })
            .catch((error: any) => {
                const errorData: AxiosErrorData = error.response?.data as AxiosErrorData;

                showErrorMessage({
                    text: errorData?.message,
                });
            });
    }

    /**
     * Updates a restaurant category with the given id
     *
     * @param id RestaurantCategory id
     * @param updateRequest UpdateRestaurantCategoryRequest
     * @param config AxiosRequestConfig
     * @returns RestaurantCategory
     */
    public static async update(
        id: number | string,
        updateRequest: UpdateRestaurantCategoryRequest,
        config?: AxiosRequestConfig
    ): Promise<RestaurantCategory> {
        const formData = new FormData();

        formData.append('name', JSON.stringify(updateRequest.name));

        if (updateRequest.image && updateRequest.image.startsWith('blob:')) {
            const response = await fetch(updateRequest.image);

            formData.append('image', await response.blob());
        }

        formData.append('_method', 'PATCH');

        return await axios
            .post(`${RestaurantCategoriesService.url}/${id}`, formData, config)
            .then((response: AxiosResponse) => {
                return response.data;
            })
            .catch((error: any) => {
                const errorData: AxiosErrorData = error.response?.data as AxiosErrorData;

                showErrorMessage({
                    text: errorData?.message,
                });
            });
    }

    /**
     * Deletes a restaurant category with the given id
     *
     * @param id RestaurantCategory id
     * @param config AxiosRequestConfig
     * @returns boolean
     */
    public static delete(id: number | string, config?: AxiosRequestConfig): Promise<boolean> {
        return new ResourceService<RestaurantCategory>(axios, RestaurantCategoriesService.url).delete(id, config);
    }
}
