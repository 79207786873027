import store from '@/store';

import { load } from './load';

import type { StoreUser } from './../models/user.model';
import type Restaurant from '@/models/restaurant.model';
import type Location from '@/models/location.model';

function addUserPermissions(user: StoreUser) {
    const permissions = [];

    if (user.admin) {
        user.permissions = ['*'];
        user.can = (_: string) => true;

        return;
    }

    for (const restaurant of user.restaurants!) {
        permissions.push(`restaurant.${restaurant.id}.*`);
    }

    for (const locationRole of user.locations!) {
        const location = locationRole.location;
        const role = locationRole.role;

        if (role == 'Manager') {
            permissions.push(`restaurant.${location.restaurant_id}.locations`);
            permissions.push(`restaurant.${location.restaurant_id}.location.${location.id}.*`);

            continue;
        }

        permissions.push(`restaurant.${location.restaurant_id}.location.${location.id}.dashboard`);
        permissions.push(`restaurant.${location.restaurant_id}.location.${location.id}.kitchen`);
        permissions.push(`restaurant.${location.restaurant_id}.location.${location.id}.front-desk`);
    }

    user.permissions = permissions;

    user.can = function (permission: string) {
        let hasPermission = false;

        const restaurantId = store.activeRestaurant?.id as string;
        const locationId = '' + store.activeLocation?.id;

        permission = permission.replace('{locationId}', locationId).replace('{restaurantId}', restaurantId);

        for (const userPermission of user.permissions) {
            if (RegExp(userPermission.replace('*', '.*')).test(permission)) {
                hasPermission = true;

                break;
            }
        }

        return hasPermission;
    };
}

export async function preload() {
    // Not logged in
    if (!store.user?.id) {
        return;
    }

    // Add User Permissions
    addUserPermissions(store.user);

    // If the User is an admin, load all restaurants.
    if (store.user.admin) {
        [store.restaurants, store.locations] = await load('restaurants', 'locations');

        return;
    }

    let restaurants: Restaurant[] = [];
    let locations: Location[] = [];

    /**
     * If the user has restaurants, add them to the array
     */
    if (store.user.restaurants?.length) {
        restaurants = [...restaurants, ...store.user.restaurants];
    }

    if (store.user.locations?.length) {
        for (const locationRole of store.user.locations) {
            const restaurant = restaurants.find((restaurant) => restaurant.id == locationRole.location.restaurant.id);

            if (restaurant) {
                restaurant.locations = [...restaurant.locations, locationRole.location];

                continue;
            }

            restaurants.push({
                ...locationRole.location.restaurant,
                locations: [locationRole.location],
            });
        }
    }

    for (const restaurant of restaurants) {
        locations = [...locations, ...restaurant.locations];
    }

    store.restaurants = restaurants;
    store.locations = locations;
}
