import axios, { type AxiosRequestConfig } from 'axios';

import ResourceService from './_resource.service';

import type Menu from '@/models/menu.model';

import type CreateMenuRequest from '@/requests/create-menu.request';
import type UpdateMenuRequest from '@/requests/update-menu.request';
import type Product from '@/models/product.model';

export default class MenusService extends ResourceService<Menu> {
    public static url = `${import.meta.env.VITE_API_URL}/menus`;

    constructor() {
        super(axios, MenusService.url);
    }

    /**
     * Get all menus
     * @param config
     * @returns
     */
    public static index(config?: AxiosRequestConfig): Promise<Menu[]> {
        return new ResourceService<Menu>(axios, MenusService.url).index(config);
    }

    /**
     * Get single menu
     * @param id
     * @param config
     * @returns
     */
    public static show(id: number | string, config?: AxiosRequestConfig): Promise<Menu> {
        return new ResourceService<Menu>(axios, MenusService.url).show(id, config);
    }

    /**
     * Create menu
     * @param createRequest
     * @param config
     * @returns
     */
    public static store(createRequest: CreateMenuRequest, config?: AxiosRequestConfig): Promise<Menu> {
        return new ResourceService<CreateMenuRequest, Menu>(axios, MenusService.url).store(createRequest, config);
    }

    /**
     *  Update menu
     * @param id
     * @param updateRequest
     * @param config
     * @returns
     */
    public static update(
        id: number | string,
        updateRequest: UpdateMenuRequest,
        config?: AxiosRequestConfig
    ): Promise<Menu> {
        return new ResourceService<UpdateMenuRequest, Menu>(axios, MenusService.url).update(id, updateRequest, config);
    }

    /**
     * Delete menu
     * @param id
     * @param config
     * @returns
     */
    public static delete(id: number | string, config?: AxiosRequestConfig): Promise<boolean> {
        return new ResourceService<Menu>(axios, MenusService.url).delete(id);
    }

    /**
     * Add product
     * @param id
     * @param data
     * @param config
     * @returns
     */
    public static async addProduct(
        id: number | string,
        data: {
            product_id: number;
        },
        config?: AxiosRequestConfig<any>
    ): Promise<Product> {
        return axios
            .post(`${MenusService.url}/${id}/product`, data, config)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                console.log(error);
            });
    }

    /**
     * Remove product
     * @param id
     * @param productId
     * @param config
     * @returns
     */
    public static async removeProduct(
        id: number | string,
        productId: number | string,
        config?: AxiosRequestConfig
    ): Promise<Menu[]> {
        return axios
            .delete(`${MenusService.url}/${id}/product/${productId}`, config)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                console.log(error);
            });
    }

    /**
     * Update order products
     * @param id Menu id
     * @param config AxiosRequestConfig
     * @returns
     */
    public static async orderProducts(
        id: number | string,
        product_ids: number[],
        config?: AxiosRequestConfig
    ): Promise<Menu[]> {
        return axios
            .patch(`${MenusService.url}/${id}/order-products`, { product_ids }, config)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                console.log(error);
            });
    }
}
