<script setup lang="ts">
import { computed } from 'vue';

import store from '@/store';
import { icons } from '@/helpers/icons';

// Computed

const active = computed(() => {
    return !!store.popup.active;
});

// Functions

function containerClicked(e: Event) {
    if (store.popup.required) {
        return;
    }

    const target = e.target as HTMLElement;

    if (target.classList.contains('fixed')) {
        close();
    }
}

function close() {
    store.popup.active = false;
}
</script>

<template>
    <Teleport to="body">
        <div
            @click="containerClicked"
            class="fixed left-0 top-0 z-40 grid h-full w-full place-items-center bg-dark-500/30 px-2 text-center transition"
            :class="{
                'pointer-events-none opacity-0': !active,
            }"
        >
            <div
                class="w-full max-w-sm rounded-lg bg-white p-8 transition duration-300"
                :class="{
                    'translate-y-96': !active,
                    'absolute bottom-3 right-1.5': store.popup.position == 'bottom-right',
                    'absolute right-1.5 top-36': store.popup.position == 'top-right',
                }"
            >
                <!-- Danger Icon -->
                <i
                    v-if="store.popup.type == 'danger'"
                    v-html="icons.danger"
                    class="mx-auto mb-2 block h-4 w-4 text-[#EB5757]"
                >
                </i>

                <!-- Title -->
                <div
                    v-if="store.popup.title"
                    class="mb-2 text-xl font-bold"
                >
                    {{ store.popup.title }}
                </div>

                <!-- Text -->
                <div
                    v-if="store.popup.text"
                    v-html="store.popup.text"
                    class="mb-8 text-sm"
                ></div>

                <!-- Buttons -->
                <div class="flex space-x-2">
                    <a
                        v-for="(button, index) in store.popup.buttons"
                        :key="index"
                        href="#"
                        @click.prevent="
                            // If the Button has a Callback, call it
                            button.callback && button.callback();

                            // Close the popup
                            close();
                        "
                        class="btn w-full"
                        :class="{
                            outlined: button.outlined,
                            red: store.popup.type == 'danger',
                        }"
                    >
                        {{ button.text }}
                    </a>
                </div>
            </div>
        </div>
    </Teleport>
</template>
