import axios, { AxiosError, type AxiosRequestConfig, type AxiosResponse } from 'axios';

import { error as showErrorMessage } from '@/helpers/error';

import ResourceService from './_resource.service';

import type Product from '@/models/product.model';
import type { AxiosErrorData } from '@/models/error.model';

import type CreateProductRequest from '@/requests/create-product.request';
import type UpdateProductRequest from '@/requests/update-product.request';

export default class ProductsService extends ResourceService<Product> {
    public static url = `${import.meta.env.VITE_API_URL}/products`;

    constructor() {
        super(axios, ProductsService.url);
    }

    /**
     * Gets all products
     *
     * @param config AxiosRequestConfig
     * @returns Product[]
     */
    public static index(config?: AxiosRequestConfig): Promise<Product[]> {
        return new ResourceService<Product>(axios, ProductsService.url).index(config);
    }

    /**
     * Gets a single product with the given id
     *
     * @param id Product id
     * @param config AxiosRequestConfig
     * @returns Product
     */
    public static show(id: number | string, config?: AxiosRequestConfig): Promise<Product> {
        return new ResourceService<Product>(axios, ProductsService.url).show(id, config);
    }

    /**
     * Creates a new product
     *
     * @param createRequest CreateProductRequest
     * @param config AxiosRequestConfig
     * @returns Product
     */
    public static async store(createRequest: CreateProductRequest, config?: AxiosRequestConfig): Promise<Product> {
        const formData = new FormData();

        formData.append('name', createRequest.name ?? '');
        formData.append('price', createRequest.price ?? '');
        formData.append('pos_price', createRequest.pos_price ?? '');
        formData.append('purchase_price', createRequest.purchase_price ?? '');
        formData.append('type', createRequest.type ?? '');
        formData.append('production_time', createRequest.production_time?.toString() ?? '');

        if (createRequest.variants) {
            formData.append('variants', createRequest.variants ?? '');
        }

        if (createRequest.ingredients_list) {
            formData.append('ingredients_list', createRequest.ingredients_list ?? '');
        }

        if (createRequest.restaurant_id) {
            formData.append('restaurant_id', createRequest.restaurant_id.toString());
        }

        formData.append('product_category_id', createRequest.product_category_id?.toString() || '');

        if (createRequest.image) {
            const response = await fetch(createRequest.image);

            formData.append('image', await response.blob());
        }

        return await axios
            .post(`${ProductsService.url}`, formData, config)
            .then((response: AxiosResponse) => {
                return response.data;
            })
            .catch((error: AxiosError) => {
                const errorData: AxiosErrorData = error.response?.data as AxiosErrorData;

                showErrorMessage({
                    text: errorData?.message,
                });
            });
    }

    /**
     * Updates a product with the given id
     *
     * @param id Product id
     * @param updateRequest UpdateProductRequest
     * @param config AxiosRequestConfig
     * @returns Product
     */
    public static async update(
        id: number | string,
        updateRequest: UpdateProductRequest,
        config?: AxiosRequestConfig
    ): Promise<Product> {
        const formData = new FormData();

        if (updateRequest.name) {
            formData.append('name', updateRequest.name ?? '');
        }

        if (updateRequest.price) {
            formData.append('price', updateRequest.price ?? '');
        }

        if (updateRequest.pos_price) {
            formData.append('pos_price', updateRequest.pos_price ?? '');
        }

        if (updateRequest.purchase_price) {
            formData.append('purchase_price', updateRequest.purchase_price ?? '');
        }

        formData.append('production_time', updateRequest.production_time?.toString?.() ?? '');

        if (updateRequest.variants) {
            formData.append('variants', updateRequest.variants ?? '');
        }

        if (updateRequest.ingredients_list) {
            formData.append('ingredients_list', updateRequest.ingredients_list ?? '');
        }

        if (updateRequest.restaurant_id) {
            formData.append('restaurant_id', updateRequest.restaurant_id ?? '');
        }

        formData.append('product_category_id', updateRequest.product_category_id?.toString() || '');

        if (updateRequest.image?.startsWith?.('blob:')) {
            const response = await fetch(updateRequest.image);

            formData.append('image', await response.blob());
        }

        formData.append('_method', 'PATCH');

        return await axios
            .post(`${ProductsService.url}/${id}`, formData, config)
            .then((response: AxiosResponse) => {
                return response.data;
            })
            .catch((error: AxiosError) => {
                const errorData: AxiosErrorData = error.response?.data as AxiosErrorData;

                showErrorMessage({ text: errorData?.message || '' });
            });
    }

    /**
     * Deletes a product with the given id
     *
     * @param id Product id
     * @param config AxiosRequestConfig
     * @returns boolean
     */
    public static delete(id: number | string, config?: AxiosRequestConfig): Promise<boolean> {
        return new ResourceService<Product>(axios, ProductsService.url).delete(id, config);
    }

    public static reorder(product_ids: number[]) {
        return axios
            .post(`${ProductsService.url}/reorder`, { product_ids })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                console.log(error);
            });
    }
}
