import { error as showErrorMessage } from '@/helpers/error';

import type { AxiosResponse, AxiosError, AxiosStatic, AxiosRequestConfig } from 'axios';

import AuthService from './auth.service';

export default class ResourceService<Type, ReturnType = Type> {
    private axios: AxiosStatic;
    private url: string;

    constructor(axios: AxiosStatic, url: string) {
        this.axios = axios;
        this.url = url;
    }

    /**
     * Gets all the resources
     *
     * @param config AxiosRequestConfig
     * @returns Resource[]
     */
    async index(config?: AxiosRequestConfig): Promise<ReturnType[]> {
        return await this.axios
            .get<Type[]>(this.url, config)
            .then((response: AxiosResponse) => {
                return response.data;
            })
            .catch((error: AxiosError) => {
                this.handleError(error);
            });
    }

    /**
     * Gets a single resource with the given id
     *
     * @param id Resource id
     * @param config AxiosRequestConfig
     * @returns Resource
     */
    async show(id: string | number, config?: AxiosRequestConfig): Promise<ReturnType> {
        return await this.axios
            .get<Type>(`${this.url}/${id}`, config)
            .then((response: AxiosResponse) => {
                return response.data;
            })
            .catch((error: AxiosError) => {
                this.handleError(error);
            });
    }

    /**
     * Creates a new resource
     *
     * @param createResourceRequest Type
     * @param config AxiosRequestConfig
     * @returns Resource
     */
    async store(createResourceRequest: Type, config?: AxiosRequestConfig): Promise<ReturnType> {
        return await this.axios
            .post<Type>(this.url, createResourceRequest, config)
            .then((response: AxiosResponse) => {
                return response.data;
            })
            .catch((error: AxiosError) => {
                this.handleError(error);
            });
    }

    /**
     * Updates a resource with the given id
     *
     * @param id Resource id
     * @param updateResourceRequest Type
     * @param config AxiosRequestConfig
     * @returns Resource
     */
    async update(id: string | number, updateResourceRequest: Type, config?: AxiosRequestConfig): Promise<ReturnType> {
        return await this.axios
            .patch<Type>(`${this.url}/${id}`, updateResourceRequest, config)
            .then((response: AxiosResponse) => {
                return response.data;
            })
            .catch((error: AxiosError) => {
                this.handleError(error);
            });
    }

    /**
     * Deletes a resource with the given id
     *
     * @param id Resource id
     * @param config AxiosRequestConfig
     * @returns boolean
     */
    async delete(id: string | number, config?: AxiosRequestConfig): Promise<boolean> {
        return await this.axios
            .delete<boolean>(`${this.url}/${id}`, config)
            .then(() => {
                return true;
            })
            .catch(() => {
                return false;
            });
    }

    /**
     * Handles request erros
     *
     * @param error any
     */
    private handleError(error: AxiosError): void {

        // If Unauthorized, check if the user is logged in.
        // Reload the page, if the user is not logged in.
        if (this.axios.isAxiosError(error)) {
            if (error.response?.status == 401) {
                (async () => {
                    const user = await AuthService.me();

                    if (!user) {
                        localStorage.removeItem('token');
                        localStorage.removeItem('activeRestaurant');

                        window.location.reload();
                    }
                })();
            }

            if (!error?.response?.data?.message) {
                return;
            }

            showErrorMessage({
                text: error?.response?.data?.message,
            });
        }
    }
}
