import { onMounted, watch } from 'vue';

import { useRoute } from 'vue-router';

const eventHandlers: { name: string; callback: Function }[] = [];

export const EventBus = {
    /**
     * Initialize the Event Bus (in App.vue).
     * Clears the event handlers when routing to a new page.
     */
    init() {
        const route = useRoute();

        function clear() {
            while (eventHandlers.length) {
                eventHandlers.pop();
            }
        }

        onMounted(() => {
            clear();
        });

        watch(() => route.name, clear);
    },

    /**
     * Add an event handler.
     *
     * @param name - The name of the event.
     * @param callback
     */
    listen(name: string, callback: Function) {
        eventHandlers.push({ name: name, callback });
    },

    /**
     * Emit an event.
     *
     * @param name - The name of the event.
     * @param args
     */
    emit(name: string, ...args: any[]) {
        const handlers = eventHandlers.filter((handler) => handler.name == name);

        for (const handler of handlers) {
            handler.callback?.(...args);
        }
    },
};
