<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';

import { useRoute, useRouter } from 'vue-router';

import store from '@/store';

import { icons } from '@/helpers/icons';
import { popupConfirm } from '@/helpers/popup';

import VLanguage from '@/components/VLanguage.vue';

const route = useRoute();
const router = useRouter();

const locationSelectActive = ref(false);

const active = computed(() => store.showSidebar);

watch(active, (active) => {
    if (active) {
        return;
    }

    setTimeout(() => {
        locationSelectActive.value = false;
    }, 200);
});

function closeOnEscape(e: KeyboardEvent) {
    if (e.key == 'Escape') {
        store.showSidebar = false;
    }
}

onMounted(() => {
    window.addEventListener('keydown', closeOnEscape);
});

onUnmounted(() => {
    window.removeEventListener('keydown', closeOnEscape);
});

/**
 * Log the User out.
 */
function logout() {
    popupConfirm('Log Out', 'Are you sure you want to log out?', () => {
        localStorage.removeItem('token');
        localStorage.removeItem('activeRestaurant');

        store.user = null;

        router.push({ name: 'login' });

        setTimeout(() => {
            window.location.reload();
        }, 100);
    });
}
</script>

<template>
    <Teleport to="body">
        <div
            class="fixed left-0 top-0 z-50 h-full w-full bg-dark-500/30 transition"
            :class="{ 'pointer-events-none opacity-0': !active }"
        >
            <aside
                v-click-away="
                    () => {
                        store.showSidebar = false;
                    }
                "
                style="grid-template-rows: auto 1fr"
                class="fixed left-0 top-0 grid h-full overflow-y-auto bg-white p-6 py-8 text-sm transition 2xl:w-96"
                :class="{ '-translate-x-full': !active }"
            >
                <div>
                    <div class="mb-5 flex items-center justify-between pl-6">
                        <div>
                            <i v-html="icons.logo"></i>
                        </div>

                        <!-- Language -->
                        <VLanguage />
                    </div>

                    <!-- Restaurant & Location -->
                    <div
                        v-if="store.activeRestaurant"
                        class="mb-5 rounded-lg bg-dark-yellow-500/20 text-xs font-bold"
                    >
                        <RouterLink
                            :to="{
                                name: 'restaurant',
                                params: {
                                    restaurantId: store.activeRestaurant.id,
                                },
                            }"
                            @click="store.showSidebar = false"
                            class="block rounded-lg bg-dark-500 px-6 py-3 text-white hover:bg-dark-600"
                        >
                            {{ store.activeRestaurant.name }}
                        </RouterLink>

                        <div
                            v-if="store.activeRestaurant?.locations?.length"
                            class="cursor-pointer rounded-lg"
                        >
                            <div
                                @click="locationSelectActive = !locationSelectActive"
                                class="flex items-center justify-between space-x-2 px-6 py-3"
                            >
                                <div>{{ store.activeLocation?.name }}</div>

                                <i
                                    v-if="store.activeRestaurant.locations.length > 1"
                                    v-html="icons.chevron"
                                    :class="{ 'rotate-180': locationSelectActive }"
                                ></i>
                            </div>

                            <div
                                v-if="store.activeRestaurant?.locations?.length > 1 && locationSelectActive"
                                class="cursor-pointer px-3 pb-1 font-normal"
                            >
                                <RouterLink
                                    v-for="location in store.activeRestaurant.locations.filter(
                                        (location) => location.id != store.activeLocation?.id
                                    )"
                                    @click="
                                        store.activeLocation = location;
                                        store.showSidebar = false;
                                    "
                                    :to="{
                                        name: 'dashboard',
                                        params: {
                                            restaurantId: store.activeRestaurant?.id,
                                            locationId: location.id,
                                        },
                                    }"
                                    class="block rounded p-3 last:mb-0 hover:bg-black/5"
                                >
                                    {{ location.name }}
                                </RouterLink>
                            </div>
                        </div>
                    </div>
                </div>

                <nav class="flex flex-col justify-between text-dark-400">
                    <div
                        v-if="!store.activeRestaurant?.id"
                        class="my-8 text-center italic text-gray-400"
                    >
                        {{ $t('restaurants.selectRestaurant') }}
                    </div>

                    <div v-if="store.activeRestaurant?.id">
                        <RouterLink
                            v-if="
                                store.activeLocation?.id &&
                                store.user?.can('restaurant.{restaurantId}.location.{locationId}.dashboard')
                            "
                            :to="{
                                name: 'dashboard',
                                params: {
                                    restaurantId: store.activeRestaurant?.id,
                                    locationId: store.activeLocation?.id,
                                },
                            }"
                            @click="store.showSidebar = false"
                            class="block min-w-[224px] rounded-lg px-6 py-3 font-semibold"
                            :class="{
                                'hover:bg-dark-300/10': route.name != 'dashboard',
                                'bg-[#fdd395] text-dark-500 hover:bg-[#f1c786]': route.name == 'dashboard',
                            }"
                        >
                            {{ $t('dashboard.title') }}
                        </RouterLink>

                        <RouterLink
                            v-if="
                                store.activeLocation?.id &&
                                store.user?.can('restaurant.{restaurantId}.location.{locationId}.front-desk')
                            "
                            :to="{
                                name: 'front-desk',
                                params: {
                                    restaurantId: store.activeRestaurant?.id,
                                    locationId: store.activeLocation?.id,
                                },
                            }"
                            @click="store.showSidebar = false"
                            class="block min-w-[224px] rounded-lg px-6 py-3 font-semibold"
                            :class="{
                                'hover:bg-dark-300/10': route.name != 'front-desk',
                                'bg-[#fdd395] text-dark-500 hover:bg-[#f1c786]': route.name == 'front-desk',
                            }"
                        >
                            {{ $t('frontDesk.title') }}
                        </RouterLink>

                        <RouterLink
                            v-if="
                                store.activeLocation?.id &&
                                store.user?.can('restaurant.{restaurantId}.location.{locationId}.kitchen')
                            "
                            :to="{
                                name: 'kitchen',
                                params: {
                                    restaurantId: store.activeRestaurant?.id,
                                    locationId: store.activeLocation?.id,
                                },
                            }"
                            @click="store.showSidebar = false"
                            class="block min-w-[224px] rounded-lg px-6 py-3 font-semibold"
                            :class="{
                                'hover:bg-dark-300/10': route.name != 'kitchen',
                                'bg-[#fdd395] text-dark-500 hover:bg-[#f1c786]': route.name == 'kitchen',
                            }"
                        >
                            {{ $t('kitchen.title') }}
                        </RouterLink>

                        <RouterLink
                            v-if="
                                store.activeLocation?.id &&
                                store.user?.can('restaurant.{restaurantId}.location.{locationId}.orders')
                            "
                            :to="{
                                name: 'orders',
                                params: {
                                    restaurantId: store.activeRestaurant?.id,
                                    locationId: store.activeLocation?.id,
                                },
                            }"
                            @click="store.showSidebar = false"
                            class="block min-w-[224px] rounded-lg px-6 py-3 font-semibold"
                            :class="{
                                'hover:bg-dark-300/10': route.name != 'orders',
                                'bg-[#fdd395] text-dark-500 hover:bg-[#f1c786]': route.name == 'orders',
                            }"
                        >
                            {{ $t('orders.title') }}
                        </RouterLink>

                        <RouterLink
                            v-if="store.user?.can('restaurant.{restaurantId}.products')"
                            :to="{ name: 'products', params: { restaurantId: store.activeRestaurant?.id } }"
                            @click="store.showSidebar = false"
                            class="block min-w-[224px] rounded-lg px-6 py-3 font-semibold"
                            :class="{
                                'hover:bg-dark-300/10': route.name != 'products',
                                'bg-[#fdd395] text-dark-500 hover:bg-[#f1c786]': route.name == 'products',
                            }"
                        >
                            {{ $t('products.title') }}
                        </RouterLink>

                        <RouterLink
                            v-if="store.user?.can('restaurant.{restaurantId}.product-categories')"
                            :to="{ name: 'product-categories', params: { restaurantId: store.activeRestaurant?.id } }"
                            @click="store.showSidebar = false"
                            class="block min-w-[224px] rounded-lg px-6 py-3 font-semibold"
                            :class="{
                                'hover:bg-dark-300/10': route.name != 'product-categories',
                                'bg-[#fdd395] text-dark-500 hover:bg-[#f1c786]': route.name == 'product-categories',
                            }"
                        >
                            {{ $t('productCategories.title') }}
                        </RouterLink>

                        <RouterLink
                            v-if="store.user?.can('restaurant.{restaurantId}.menus')"
                            :to="{ name: 'menus', params: { restaurantId: store.activeRestaurant?.id } }"
                            @click="store.showSidebar = false"
                            class="block min-w-[224px] rounded-lg px-6 py-3 font-semibold"
                            :class="{
                                'hover:bg-dark-300/10': route.name != 'menus',
                                'bg-[#fdd395] text-dark-500 hover:bg-[#f1c786]': route.name == 'menus',
                            }"
                        >
                            {{ $t('menus.title') }}
                        </RouterLink>

                        <RouterLink
                            v-if="store.user?.can('restaurant.{restaurantId}.locations')"
                            :to="{ name: 'locations', params: { restaurantId: store.activeRestaurant?.id } }"
                            @click="store.showSidebar = false"
                            class="block min-w-[224px] rounded-lg px-6 py-3 font-semibold"
                            :class="{
                                'hover:bg-dark-300/10': route.name != 'locations',
                                'bg-[#fdd395] text-dark-500 hover:bg-[#f1c786]': route.name == 'locations',
                            }"
                        >
                            {{ $t('locations.title') }}
                        </RouterLink>

                        <RouterLink
                            v-if="store.user?.can('restaurant.{restaurantId}.users')"
                            :to="{ name: 'users', params: { restaurantId: store.activeRestaurant?.id } }"
                            @click="store.showSidebar = false"
                            class="block min-w-[224px] rounded-lg px-6 py-3 font-semibold"
                            :class="{
                                'hover:bg-dark-300/10': route.name != 'users',
                                'bg-[#fdd395] text-dark-500 hover:bg-[#f1c786]': route.name == 'users',
                            }"
                        >
                            {{ $t('profile.users') }}
                        </RouterLink>

                        <RouterLink
                            v-if="
                                store.activeLocation?.id &&
                                store.user?.can('restaurant.{restaurantId}.location.{locationId}.reports')
                            "
                            :to="{
                                name: 'reports',
                                params: {
                                    restaurantId: store.activeRestaurant?.id,
                                    locationId: store.activeLocation?.id,
                                },
                            }"
                            @click="store.showSidebar = false"
                            class="block min-w-[224px] rounded-lg px-6 py-3 font-semibold"
                            :class="{
                                'hover:bg-dark-300/10': route.name != 'reports',
                                'bg-[#fdd395] text-dark-500 hover:bg-[#f1c786]': route.name == 'reports',
                            }"
                        >
                            {{ $t('reports.title') }}
                        </RouterLink>
                        
                        <!-- Cash registers  -->
                        <RouterLink
                            v-if="
                                store.activeLocation?.id &&
                                store.user?.can('restaurant.{restaurantId}.location.{locationId}.cash-registers') &&
                                store.user?.admin
                            "
                            :to="{
                                name: 'cash-registers',
                                params: {
                                    restaurantId: store.activeRestaurant?.id,
                                    locationId: store.activeLocation?.id,
                                },
                            }"
                            @click="store.showSidebar = false"
                            class="block min-w-[224px] rounded-lg px-6 py-3 font-semibold"
                            :class="{
                                'hover:bg-dark-300/10': route.name != 'cash-registers',
                                'bg-[#fdd395] text-dark-500 hover:bg-[#f1c786]': route.name == 'cash-registers',
                            }"
                        >
                            {{ $t('cashRegister.cashRegisters') }}
                        </RouterLink>

                        <!-- Vibrant Management -->
                        <RouterLink
                            v-if="
                                store.activeLocation?.id &&
                                store.user?.can('restaurant.{restaurantId}.location.{locationId}/vibrant-management') &&
                                store.user?.admin &&
                                (store.activeLocation.vibrant_enabled && store.activeLocation.vibrant_api_key)
                            "
                            :to="{
                                name: 'vibrant-management',
                                params: {
                                    restaurantId: store.activeRestaurant?.id,
                                    locationId: store.activeLocation?.id,
                                },
                            }"
                            @click="store.showSidebar = false"
                            class="block min-w-[224px] rounded-lg px-6 py-3 font-semibold"
                            :class="{
                                'hover:bg-dark-300/10': route.name != 'reports',
                                'bg-[#fdd395] text-dark-500 hover:bg-[#f1c786]': route.name == 'vibrant-management',
                            }"
                        >
                            {{ $t('vibrant.vibrantManagement') }}
                        </RouterLink>
                    </div>

                    <div class="mt-12">
                        <RouterLink
                            :to="{ name: 'restaurants' }"
                            @click="store.showSidebar = false"
                            class="block min-w-[224px] rounded-lg px-6 py-3 font-semibold"
                            :class="{
                                'hover:bg-dark-300/10': route.name != 'restaurants',
                                'bg-[#fdd395] text-dark-500 hover:bg-[#f1c786]': route.name == 'restaurants',
                            }"
                        >
                            {{ $t('restaurants.title') }}
                        </RouterLink>

                        <RouterLink
                            v-if="store.user?.can('*')"
                            :to="{ name: 'restaurantCategories' }"
                            @click="store.showSidebar = false"
                            class="block min-w-[224px] rounded-lg px-6 py-3 font-semibold"
                            :class="{
                                'hover:bg-dark-300/10': route.name != 'restaurantCategories',
                                'bg-[#fdd395] text-dark-500 hover:bg-[#f1c786]': route.name == 'restaurantCategories',
                            }"
                        >
                            {{ $t('restaurants.restaurantCategory.title') }}
                        </RouterLink>

                        <RouterLink
                            :to="{ name: 'my-profile' }"
                            @click="store.showSidebar = false"
                            class="block min-w-[224px] rounded-lg px-6 py-3 font-semibold"
                            :class="{
                                'hover:bg-dark-300/10': route.name != 'my-profile',
                                'bg-[#fdd395] text-dark-500 hover:bg-[#f1c786]': route.name == 'my-profile',
                            }"
                        >
                            {{ $t('profile.myProfile') }}
                        </RouterLink>

                        <a
                            href="#"
                            @click.prevent="logout"
                            @click="store.showSidebar = false"
                            class="block min-w-[224px] rounded-lg px-6 py-3 font-semibold hover:bg-dark-300/10"
                        >
                            {{ $t('login.logout') }}
                        </a>
                    </div>
                </nav>
            </aside>
        </div>
    </Teleport>
</template>
