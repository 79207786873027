import axios, { type AxiosRequestConfig } from 'axios';

import ResourceService from './_resource.service';

import type IngredientGroup from '@/models/ingredient-group.model';

import type CreateIngredientGroupRequest from '@/requests/create-ingredient-group.request';
import type UpdateIngredientGroupRequest from '@/requests/update-ingredient-group.request';

export default class IngredientGroupsService extends ResourceService<IngredientGroup> {
    public static url = `${import.meta.env.VITE_API_URL}/ingredient-groups`;

    constructor() {
        super(axios, IngredientGroupsService.url);
    }

    /**
     * Gets all ingredient groups
     *
     * @param config AxiosRequestConfig
     * @returns IngredientGroup[]
     */
    public static index(config?: AxiosRequestConfig): Promise<IngredientGroup[]> {
        return new ResourceService<IngredientGroup>(axios, IngredientGroupsService.url).index(config);
    }

    /**
     * Gets a single ingredient group with the given id
     *
     * @param id IngredientGroup id
     * @param config AxiosRequestConfig
     * @returns IngredientGroup
     */
    public static show(id: number | string, config?: AxiosRequestConfig): Promise<IngredientGroup> {
        return new ResourceService<IngredientGroup>(axios, IngredientGroupsService.url).show(id, config);
    }

    /**
     * Creates a new ingredient group
     *
     * @param createRequest CreateIngredientGroupRequest
     * @param config AxiosRequestConfig
     * @returns IngredientGroup
     */
    public static store(
        createRequest: CreateIngredientGroupRequest,
        config?: AxiosRequestConfig
    ): Promise<IngredientGroup> {
        return new ResourceService<CreateIngredientGroupRequest, IngredientGroup>(
            axios,
            IngredientGroupsService.url
        ).store(createRequest, config);
    }

    /**
     * Updates an ingredient group with the given id
     *
     * @param id IngredientGroup id
     * @param updateRequest UpdateIngredientGroupRequest
     * @param config AxiosRequestConfig
     * @returns IngredientGroup
     */
    public static update(
        id: number | string,
        updateRequest: UpdateIngredientGroupRequest,
        config?: AxiosRequestConfig
    ): Promise<IngredientGroup> {
        return new ResourceService<UpdateIngredientGroupRequest, IngredientGroup>(
            axios,
            IngredientGroupsService.url
        ).update(id, updateRequest, config);
    }

    /**
     * Deletes an ingredient group with the given id
     *
     * @param id IngredientGroup id
     * @param config AxiosRequestConfig
     * @returns boolean
     */
    public static delete(id: number | string, config?: AxiosRequestConfig): Promise<boolean> {
        return new ResourceService<IngredientGroup>(axios, IngredientGroupsService.url).delete(id, config);
    }
}
