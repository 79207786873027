import { reactive } from 'vue';

import type User from '@/models/user.model';
import type Restaurant from '@/models/restaurant.model';
import type Ingredient from '@/models/ingredient.model';
import type { Attribute } from '@/models/variant.model';
import type Location from '@/models/location.model';
import type IngredientGroup from '@/models/ingredient-group.model';
import type Menu from '@/models/menu.model';
import type Product from '@/models/product.model';
import type RestaurantCategory from '@/models/restaurant-category.model';
import type FoodType from './models/food.type';
import type { OrderMapped } from './models/order.model';
import type { ProductMapped } from '@/models/product.model';
import type Popup from './models/popup.model';
import type Error from './models/error.model';
import type Success from '@/models/success.model';
import type { ProductCategoryMapped } from '@/models/product-category.model';
import type { StoreUser } from '@/models/user.model';
import type Terminal from '@/models/terminal.model';

type StationSettings = {
    isOpen: boolean;
    color: string;
    listeningTo: number[];
    showFullOrder: boolean;
    notificationSound: 'alert' | 'mellow';
};

const store = reactive({
    // The User
    user: null as StoreUser | null,

    // Active Restaurant & Active Location
    activeRestaurant: null as Restaurant | null,
    activeLocation: null as Location | null,

    // Active Resource Food Type
    type: 'Food' as FoodType,

    // Popup & Error, controlled by 'popup.ts' & 'error.ts'
    popup: {} as Popup,
    error: {} as Error,
    success: {} as Success,

    posBalance: {} as {
        left_in_register: number;
        card: number;
        cash: number;
        total: number;
    },

    showSidebar: false,
    socketConnectionLost: false,

    // Station Settings
    settings: {
        isOpen: false,
        color: '#F2994A',
        listeningTo: [],
        showFullOrder: true,
        notificationSound: 'alert', // default
    } as StationSettings,

    // Available Languages
    languages: [
        { name: 'English', code: 'en' },
        { name: 'Faroese', code: 'fo' },
    ],

    // Overall loader
    loading: false,

    // Loaded Resources
    ingredients: [] as Ingredient[],
    attributes: [] as Attribute[],
    ingredientGroups: [] as IngredientGroup[],
    locations: [] as Location[],
    menus: [] as Menu[],
    products: [] as Product[],
    productCategories: [] as ProductCategoryMapped[],
    restaurants: [] as Restaurant[],
    restaurantCategories: [] as RestaurantCategory[],
    users: [] as User[],
    orders: [] as OrderMapped[],
    order: null as OrderMapped | null,

    // Temporary Resources
    product: null as ProductMapped | null,
    restaurant: {} as any,

    terminals: [] as Terminal[]
});

export default store;
