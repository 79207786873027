import { computed, ref } from 'vue';

export type Modal = {
    component: any;
    name: string;
    active: boolean;
    data: any;
    global: boolean;
};

const registeredModals = ref([] as Modal[]);

export const Modal = {
    registeredModals,

    activeModal: computed(() => {
        const activeModals = registeredModals.value.filter((modal) => modal.active);

        if (!activeModals.length) {
            return null;
        }

        return activeModals[activeModals.length - 1];
    }),

    find(name: string) {
        const modal = registeredModals.value.find((modal) => modal.name == name);

        if (!modal) {
            console.warn(`Modal "${name}" is not registered.`);

            return;
        }

        return modal;
    },

    /**
     * Registers an array of modals.
     *
     * @param modals - The array of modals to register.
     * @param global - Whether the modals are global or not.
     */
    register(modals: any[], global = false) {
        for (const modal of modals) {
            if (!modal.__name) {
                continue;
            }

            registeredModals.value.unshift({
                component: modal,
                name: modal.__name,
                active: false,
                data: {},
                global,
            });
        }
    },

    /**
     * Unregisters a modal by name or all non-global modals.
     *
     * @param name - The name of the modal to unregister, or an empty string to unregister all non-global modals.
     */
    unregister(name = '') {
        if (!name) {
            registeredModals.value = registeredModals.value.filter((modal) => modal.global);

            return;
        }

        const index = registeredModals.value.findIndex((modal) => modal.name == name);

        if (index < 0) {
            console.warn(`Modal "${name}" is not registered.`);

            return;
        }

        registeredModals.value.splice(index, 1);
    },

    /**
     * Opens a modal by name with optional data.
     *
     * @param name - The name of the modal to open.
     * @param data - The optional data to pass to the modal.
     */
    open(name: string, data = {}) {
        const modal = this.find(name);

        if (!modal) {
            return;
        }

        modal.active = true;
        modal.data = data;
    },

    /**
     * Close a modal by name, the active modal or all modals.
     *
     * @param name - The name of the modal to close, or an empty string to close all modals.
     */
    close(name = '') {
        if (!name) {
            for (const modal of registeredModals.value) {
                modal.active = false;
            }

            return;
        }

        if (name == 'active') {
            if (!this.activeModal.value) {
                return;
            }

            this.activeModal.value.active = false;

            return;
        }

        const modal = registeredModals.value.find((modal) => modal.name == name);

        if (!modal) {
            console.warn(`Modal "${name}" is not registered.`);

            return;
        }

        modal.active = false;
    },

    /**
     * Set a Modal's prop value, given the modal's name.
     *
     * @param name - The name of the modal to open.
     * @param key - The key whose value to change
     * @param value - The new value
     * @returns
     */
    set(name: string, key: string, value: any) {
        const modal = this.find(name);

        if (!modal) {
            return;
        }

        modal.data = { ...modal.data, [key]: value };
    },
};
