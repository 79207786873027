import axios, { AxiosError, type AxiosRequestConfig, type AxiosResponse } from 'axios';

import { error as showErrorMessage } from '@/helpers/error';

import ResourceService from './_resource.service';

import type Restaurant from '@/models/restaurant.model';

import type CreateTerminalRequest from '@/requests/create-terminal.request';
import type UpdateTerminalRequest from '@/requests/update-terminal.request';
import type TerminalsResponse from '@/responses/terminal.response';

import type Terminal from '@/models/terminal.model';

import type User from '@/models/user.model';
import type { AxiosErrorData } from '@/models/error.model';
import type { Update } from 'vite';

export default class TerminalsService extends ResourceService<Terminal> {
    public static url = `${import.meta.env.VITE_API_URL}/vibrant`;

    constructor() {
        super(axios, TerminalsService.url);
    }


    /**
     * Gets all Terminals
     *
     * @param config AxiosRequestConfig
     * @returns Terminal[]
     */
    public static index(vibrant_api_key: string, config?: AxiosRequestConfig): Promise<TerminalsResponse> {


        return axios
            .get(`${import.meta.env.VITE_API_URL}/vibrant/get-terminals`, {
                ...config,
                params: {
                    vibrant_api_key: vibrant_api_key
                }
            })
            .then((response: AxiosResponse) => {
                return response.data;
            })
            .catch((error: AxiosError) => {
                const errorData: AxiosErrorData = error.response?.data as AxiosErrorData;

                showErrorMessage({
                    text: errorData?.message,
                });
            });
    }

    /**
     * Gets a single Terminal with the given id
     *
     * @param id Terminal id
     * @param config AxiosRequestConfig
     * @returns Terminal
     */
    public static show(id: number | string, config?: AxiosRequestConfig): Promise<Terminal> {
        return new ResourceService<Terminal>(axios, TerminalsService.url).show(id, config);
    }

    // /**
    //  * Creates a new restaurant
    //  *
    //  * @param createRequest CreateRestaurantRequest
    //  * @param config AxiosRequestConfig
    //  * @returns Restaurant
    //  */
    // public static async store(
    //     createRequest: CreateTerminalRequest,
    //     config?: AxiosRequestConfig
    // ): Promise<Terminal> {
    //     const formData = new FormData();


    //     if (createRequest.name) {
    //         formData.append('name', createRequest.name ?? '');
    //     }

    //     if (createRequest.descriptor) {
    //         formData.append('descriptor', createRequest.descriptor ?? '');
    //     }

    //     if (createRequest.spotId) {
    //         formData.append('spotId', createRequest.spotId);
    //     }

    //     if (createRequest.mode) {
    //         formData.append('mode', createRequest.mode);
    //     }

    //     if (createRequest.virtual) {
    //         formData.append('virtual', createRequest.virtual);
    //     }

    //     return axios
    //         .post(`${TerminalsService.url}`, formData, config)
    //         .then((response: AxiosResponse) => {
    //             return response.data;
    //         })
    //         .catch((error: AxiosError) => {
    //             const errorData: AxiosErrorData = error.response?.data as AxiosErrorData;

    //             showErrorMessage({
    //                 text: errorData?.message,
    //             });
    //         });
    // }




/**
 * Creates a new terminal
 *
 * @param createRequest CreateTerminalRequest
 * @param config AxiosRequestConfig
 * @returns Terminal
 */
public static async store(
    vibrant_api_key: string,
    createRequest: CreateTerminalRequest,
    config?: AxiosRequestConfig
): Promise<Terminal> {
    // Create an object with the request data
    const params = {
        vibrant_api_key: vibrant_api_key,
        name: createRequest.name,
        descriptor: createRequest.descriptor,
        spotId: createRequest.spotId,
        mode: createRequest.mode,
        virtual: createRequest.virtual,
    };

    return axios
        .post(`${TerminalsService.url}/create-terminal`, null, {
            ...config,
            params,
        })
        .then((response: AxiosResponse) => {
            return response.data;
        })
        .catch((error: AxiosError) => {
            const errorData: AxiosErrorData = error.response?.data as AxiosErrorData;

            showErrorMessage({
                text: errorData?.message,
            });
        });
}


        /**
     * Gets designated terminal
     *
     * @param config AxiosRequestConfig
     * @returns Terminal
     */
        public static getById(vibrant_api_key: string, id: string | string[], config?: AxiosRequestConfig): Promise<TerminalsResponse> {


            return axios
                .get(`${TerminalsService.url}/get-terminal/${id}`, {
                    ...config,
                    params: {
                        vibrant_api_key: vibrant_api_key
                    }
                })
                .then((response: AxiosResponse) => {
                    return response.data;
                })
                .catch((error: AxiosError) => {
                    const errorData: AxiosErrorData = error.response?.data as AxiosErrorData;
    
                    showErrorMessage({
                        text: errorData?.message,
                    });
                });
        }


/**
 * Updates a Terminal with the given id
 *
 * @param id Terminal id
 * @param updateRequest UpdateTerminalRequest
 * @param config AxiosRequestConfig
 * @returns Terminal
 */
public static async update(
    vibrant_api_key: string,
    id: number | string,
    updateRequest: UpdateTerminalRequest,
    config?: AxiosRequestConfig
): Promise<Terminal> {
    // Create an object with the request data
    const params = {
        vibrant_api_key: vibrant_api_key,
        name: updateRequest.name ?? '',
        descriptor: updateRequest.descriptor ?? '',
        spotId: updateRequest.spotId,
        mode: updateRequest.mode,
    };

    return axios
        .post(`${TerminalsService.url}/update-terminal/${id}`, null, {
            ...config,
            params,
        })
        .then((response: AxiosResponse) => {
            return response.data;
        })
        .catch((error: AxiosError) => {
            const errorData: AxiosErrorData = error.response?.data as AxiosErrorData;

            showErrorMessage({
                text: errorData?.message,
            });
        });
}

    // /**
    //  * Updates a Terminal with the given id
    //  *
    //  * @param id Terminal id
    //  * @param updateRequest UpdateTerminalRequest
    //  * @param config AxiosRequestConfig
    //  * @returns Terminal
    //  */
    // public static async update(
    //     id: number | string,
    //     updateRequest: UpdateTerminalRequest,
    //     config?: AxiosRequestConfig
    // ): Promise<Terminal> {
    //     const formData = new FormData();

    //     if (updateRequest.name) {
    //         formData.append('name', updateRequest.name ?? '');
    //     }

    //     if (updateRequest.spotId) {
    //         formData.append('spotId', updateRequest.spotId ?? '');
    //     }

    //     if (updateRequest.descriptor) {
    //         formData.append('descriptor', updateRequest.descriptor ?? '');
    //     }

    //     if (updateRequest.mode) {
    //         formData.append('mode', updateRequest.mode);
    //     }

    //     return axios
    //         .post(`${TerminalsService.url}/update-terminal/${id}`, formData, config)
    //         .then((response: AxiosResponse) => {
    //             return response.data;
    //         })
    //         .catch((error: AxiosError) => {
    //             const errorData: AxiosErrorData = error.response?.data as AxiosErrorData;

    //             showErrorMessage({
    //                 text: errorData?.message,
    //             });
    //         });
    // }


    // /**
    //  * Get menus
    //  * @param id
    //  * @param config
    //  * @returns
    //  */
    // public static async setActive(id: number | string, active: boolean, config?: AxiosRequestConfig): Promise<Terminal[]> {
    //     console.log(active);

    //     return axios
    //         .patch(`${TerminalsService.url}/${id}`, { active }, config)
    //         .then((response) => {
    //             return response.data;
    //         })
    //         .catch((error) => {
    //             console.log(error);
    //         });
    // }




    // /**
    //  * Get users
    //  * @param id
    //  * @returns
    //  */
    // public static async spots(id: number | string): Promise<User[]> {
    //     return axios
    //         .get(`${TerminalsService.url}/${id}/users`)
    //         .then((response) => {
    //             return response.data;
    //         })
    //         .catch((error) => {
    //             console.log(error);
    //         });
    // }



    // /**
    //  * Get users
    //  * @param id
    //  * @returns
    //  */
    // public static async users(id: number | string): Promise<User[]> {
    //     return axios
    //         .get(`${TerminalsService.url}/${id}/users`)
    //         .then((response) => {
    //             return response.data;
    //         })
    //         .catch((error) => {
    //             console.log(error);
    //         });
    // }


}
