import axios, { AxiosError, type AxiosRequestConfig, type AxiosResponse } from 'axios';

import store from '@/store';

import { error as showErrorMessage } from '@/helpers/error';

import ResourceService from './_resource.service';

import type Location from '@/models/location.model';

import type CreateLocationRequest from '@/requests/create-location.request';
import type UpdateLocationRequest from '@/requests/update-location.request';
import type Menu from '@/models/menu.model';

import type DeliveryArea from '@/models/delivery-area.model';
import type { AxiosErrorData } from '@/models/error.model';

export default class LocationsService extends ResourceService<Location> {
    public static url = `${import.meta.env.VITE_API_URL}/locations`;

    constructor() {
        super(axios, LocationsService.url);
    }

    /**
     * Gets all locations
     *
     * @param config AxiosRequestConfig
     * @returns Location[]
     */
    public static index(config?: AxiosRequestConfig): Promise<Location[]> {
        return axios
            .get(`${import.meta.env.VITE_API_URL}/all-locations`, config)
            .then((response: AxiosResponse) => {
                return response.data;
            })
            .catch((error: AxiosError) => {
                const errorData: AxiosErrorData = error.response?.data as AxiosErrorData;

                showErrorMessage({
                    text: errorData?.message,
                });
            });
    }

    /**
     * Gets a single location with the given id
     *
     * @param id Location id
     * @param config AxiosRequestConfig
     * @returns Location
     */
    public static show(id: number | string, config?: AxiosRequestConfig): Promise<Location> {
        return new ResourceService<Location>(axios, LocationsService.url).show(id, config);
    }

    /**
     * Creates a new location
     *
     * @param createRequest CreateLocationRequest
     * @param config AxiosRequestConfig
     * @returns Location
     */
    public static store(createRequest: CreateLocationRequest, config?: AxiosRequestConfig): Promise<Location> {
        return new ResourceService<CreateLocationRequest, Location>(axios, LocationsService.url).store(
            createRequest,
            config
        );
    }

    /**
     * Updates a location with the given id
     *
     * @param id Location id
     * @param updateRequest UpdateLocationRequest
     * @param config AxiosRequestConfig
     * @returns Location
     */
    public static update(
        id: number | string,
        updateRequest: UpdateLocationRequest,
        config?: AxiosRequestConfig
    ): Promise<Location> {
        return new ResourceService<UpdateLocationRequest, Location>(axios, LocationsService.url).update(
            id,
            updateRequest,
            config
        );
    }

    /**
     * Deletes a location with the given id
     *
     * @param id Location id
     * @param config AxiosRequestConfig
     * @returns boolean
     */
    public static delete(id: number | string, config?: AxiosRequestConfig): Promise<boolean> {
        return new ResourceService<Location>(axios, LocationsService.url).delete(id, config);
    }

    /**
     * Menus
     */

    /**
     * Get all Menus belonging to the Location
     * @param id Location id
     * @param config AxiosRequestConfig
     * @returns Menu[]
     */
    public static async menus(id: number | string, config?: AxiosRequestConfig): Promise<Menu[]> {
        return axios
            .get(`${LocationsService.url}/${id}/menus`, config)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                console.log(error);
            });
    }


    // public static storeApiKey(locationId: number | string, config?: AxiosRequestConfig): Promise<Location> {
    //     return axios.post(`${import.meta.env.VITE_API_URL}/locations/${locationId}/store-api-key`, config)
    //     .then((response: AxiosResponse) => {
    //         return response.data;
    //     })
    //     .catch((error) => {
    //         console.log(error);
    //     });
    // }


    // public static clearApiKey(locationId: number | string, config?: AxiosRequestConfig): Promise<Location> {
    //     return axios.post(`${import.meta.env.VITE_API_URL}/locations/${locationId}/clear-api-key`, config)
    //     .then((response: AxiosResponse) => {
    //         return response.data;
    //     })
    //     .catch((error) => {
    //         console.log(error);
    //     });
    // }

    public static storeVibrantApiKey(
        locationId: number | string,
        updateRequest: UpdateLocationRequest,
        config?: AxiosRequestConfig
    ): Promise<Location> {
        return new ResourceService<UpdateLocationRequest, Location>(axios, `${import.meta.env.VITE_API_URL}/locations/${locationId}/store-api-key`).store(
            updateRequest,
            config
        );
    }

    // public static clearVibrantApiKey(
    //     locationId: number | string,
    //     config?: AxiosRequestConfig
    // ): Promise<Location> {
    //     return new ResourceService<UpdateLocationRequest, Location>(axios, `${import.meta.env.VITE_API_URL}/locations/${locationId}/store-api-key`).delete(
    //         locationId,
    //         config
    //     );
    // }

    public static clearVibrantApiKey(
        locationId: number | string,
        updateRequest: UpdateLocationRequest,
        config?: AxiosRequestConfig
    ): Promise<Location> {
        return new ResourceService<UpdateLocationRequest, Location>(axios, `${import.meta.env.VITE_API_URL}/locations/${locationId}/clear-api-key`).store(
            updateRequest,
            config
        );
    }

}
