import store from '@/store';

import { createRouter, createWebHistory } from 'vue-router';

import adminGuard from './admin-guard';
import authGuard from './auth-guard';

declare module 'vue-router' {
    interface RouteMeta {
        data?: string[];
    }
}

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
        // Redirect '/' to Login
        {
            path: '/',
            redirect: () => {
                return { name: 'login' };
            },
        },
        // Auth Routes
        {
            path: '/login',
            name: 'login',
            component: () => import('../views/LoginView.vue'),
        },
        {
            path: '/forgot-password',
            name: 'forgot-password',
            component: () => import('../views/ForgotPasswordView.vue'),
        },
        {
            path: '/reset-password',
            name: 'reset-password',
            component: () => import('../views/ResetPasswordView.vue'),
        },
        {
            path: '/invite/:token/:type',
            name: 'invite',
            component: () => import('../views/InviteView.vue'),
        },
        {
            path: '/restaurants/:restaurantId/location/:locationId/dashboard',
            name: 'dashboard',
            component: () => import('../views/DashboardView.vue'),
            meta: {
                guard: 'restaurant.{restaurantId}.location.{locationId}.dashboard',
            },
        },
        {
            path: '/restaurants/:restaurantId/location/:locationId/orders',
            name: 'orders',
            component: () => import('../views/OrdersView.vue'),
            meta: {
                guard: 'restaurant.{restaurantId}.location.{locationId}.orders',
            },
        },
        {
            path: '/restaurants/:restaurantId/location/:locationId/order/:id',
            name: 'order',
            component: () => import('../views/OrderView.vue'),
            meta: {
                guard: 'restaurant.{restaurantId}.location.{locationId}.orders',
            },
        },
        {
            path: '/restaurants/:restaurantId/location/:locationId/front-desk',
            name: 'front-desk',
            component: () => import('../views/FrontDeskView.vue'),
            meta: {
                guard: 'restaurant.{restaurantId}.location.{locationId}.front-desk',
            },
        },
        {
            path: '/restaurants/:restaurantId/location/:locationId/kitchen',
            name: 'kitchen',
            component: () => import('../views/KitchenView.vue'),
            meta: {
                guard: 'restaurant.{restaurantId}.location.{locationId}.kitchen',
            },
        },
        {
            path: '/restaurants',
            name: 'restaurants',
            component: () => import('../views/RestaurantsView.vue'),
            meta: {
                guard: 'user',
            },
        },
        {
            path: '/restaurants/:restaurantId',
            name: 'restaurant',
            component: () => import('../views/RestaurantView.vue'),
            meta: {
                guard: 'restaurant.{restaurantId}.edit',
            },
        },
        {
            path: '/restaurants/new',
            name: 'newRestaurant',
            component: () => import('../views/RestaurantView.vue'),
            meta: {
                guard: 'admin',
            },
        },
        {
            path: '/restaurants/:restaurantId/add-location',
            name: 'add-restaurant-location',
            component: () => import('../views/RestaurantView.vue'),
            meta: {
                guard: 'restaurant.{restaurantId}.add-location',
            },
        },
        {
            path: '/restaurant-categories',
            name: 'restaurantCategories',
            component: () => import('../views/RestaurantCategories.vue'),
            meta: {
                guard: 'admin',
            },
        },
        {
            path: '/restaurants/:restaurantId/locations',
            name: 'locations',
            component: () => import('../views/LocationsView.vue'),
            meta: {
                guard: 'restaurant.{restaurantId}.locations',
            },
        },
        {
            path: '/restaurants/:restaurantId/locations/:id',
            name: 'location',
            component: () => import('../views/LocationView.vue'),
            meta: {
                guard: 'restaurant.{restaurantId}.locations.{locationId}.update',
            },
        },
        {
            path: '/restaurants/:restaurantId/locations/:locationId/delivery',
            name: 'delivery',
            component: () => import('../views/DeliveryView.vue'),
            meta: {
                guard: 'restaurant.{restaurantId}.delivery',
            },
        },
        {
            path: '/restaurants/:restaurantId/products',
            name: 'products',
            component: () => import('../views/ProductsView.vue'),
            meta: {
                guard: 'restaurant.{restaurantId}.products',
            },
        },
        {
            path: '/restaurants/:restaurantId/products/:id',
            name: 'product',
            component: () => import('../views/ProductView.vue'),
            meta: {
                guard: 'restaurant.{restaurantId}.products',
            },
        },
        {
            path: '/restaurants/:restaurantId/product-categories',
            name: 'product-categories',
            component: () => import('../views/ProductCategoriesView.vue'),
            meta: {
                guard: 'restaurant.{restaurantId}.product-categories',
            },
        },
        {
            path: '/restaurants/:restaurantId/product-categories/:id',
            name: 'product-category',
            component: () => import('../views/ProductCategoryView.vue'),
            meta: {
                guard: 'restaurant.{restaurantId}.product-categories',
            },
        },
        {
            path: '/restaurants/:restaurantId/ingredients',
            name: 'ingredients',
            component: () => import('../views/IngredientsView.vue'),
            meta: {
                guard: 'restaurant.{restaurantId}.ingredients',
            },
        },
        {
            path: '/restaurants/:restaurantId/menus',
            name: 'menus',
            component: () => import('../views/MenusView.vue'),
            meta: {
                guard: 'restaurant.{restaurantId}.menus',
            },
        },
        {
            path: '/restaurants/:restaurantId/attributes',
            name: 'attributes',
            component: () => import('../views/AttributesView.vue'),
            meta: {
                guard: 'restaurant.{restaurantId}.attributes',
            },
        },
        {
            path: '/restaurants/:restaurantId/invoices',
            name: 'invoices',
            component: () => import('../views/InvoicesView.vue'),
            meta: {
                guard: 'restaurant.{restaurantId}.invoices',
            },
        },
        {
            path: '/restaurants/:restaurantId/location/:locationId/reports',
            name: 'reports',
            component: () => import('../views/ReportsView.vue'),
            meta: {
                guard: 'restaurant.{restaurantId}.location.{locationId}.reports',
            },
        },
        {
            // vibrant management
            path: '/restaurants/:restaurantId/location/:locationId/vibrant-management',
            name: 'vibrant-management',
            component: () => import('../views/VibrantManagementView.vue'),
            meta: {
                guard: 'admin',
            }
        },
        {
            // Cash registers
            path: '/restaurants/:restaurantId/location/:locationId/cash-registers',
            name: 'cash-registers',
            component: () => import('../views/CashRegistersView.vue'),
            meta: {
                guard: 'admin',
            }
        },
        {
            // Cash register
            path: '/restaurants/:restaurantId/location/:locationId/cash-registers/:id',
            name: 'cash-register',
            component: () => import('../views/CashRegisterView.vue'),
            props: route => ({
                id: route.params.id
              }),
            meta: {
                guard: 'admin',
            }
        },
        {
            path: '/restaurants/:restaurantId/location/:locationId/terminal/:id',
            name: 'terminal',
            component: () => import('../views/VibrantTerminalView.vue'),
            props: route => ({
                id: route.params.id,
                locationId: route.params.locationId
              }),
            meta: {
              guard: 'admin',
            }
          },
          {
            path: '/restaurants/:restaurantId/location/:locationId/spot/:id',
            name: 'spot',
            component: () => import('../views/VibrantSpotView.vue'),
            props: route => ({
                id: route.params.id
              }),
            meta: {
              guard: 'admin',
            }
          },
          {
            path: '/restaurants/:restaurantId/location/:locationId/vibrant-user/:id',
            name: 'vibrant-user',
            component: () => import('../views/VibrantUserView.vue'),
            props: route => ({
                id: route.params.id
              }),
            meta: {
              guard: 'admin',
            }
          },
          {
            path: '/restaurants/:restaurantId/location/:locationId/vibrant-webhook/:id',
            name: 'vibrant-webhook',
            component: () => import('../views/VibrantWebhookView.vue'),
            props: route => ({
                id: route.params.id
              }),
            meta: {
              guard: 'admin',
            }
          },
        {
            path: '/restaurants/:restaurantId/users',
            name: 'users',
            component: () => import('../views/UsersView.vue'),
            meta: {
                guard: 'restaurant.{restaurantId}.users',
            },
        },
        {
            path: '/restaurants/:restaurantId/users/:id',
            name: 'user',
            component: () => import('../views/UserView.vue'),
            meta: {
                guard: 'restaurant.{restaurantId}.users',
            },
        },
        {
            path: '/my-profile',
            name: 'my-profile',
            component: () => import('../views/MyProfileView.vue'),
            meta: {
                guard: 'user',
            },
        },
        // {
        //     path: '/restaurants/:restaurantId/location/:locationId/pos',
        //     name: 'pos',
        //     component: () => import('../views/PosView.vue'),
        //     meta: {
        //         guard: 'user',
        //     },
        // },
        {
            path: '/forbidden',
            name: '403',
            component: () => import('../views/errors/403.vue'),
        },
        {
            path: '/:any(.*)*',
            name: '404',
            component: () => import('../views/errors/404.vue'),
        },
    ],
});

router.beforeEach((to, from, next) => {
    if (!store.user?.id && to.meta.guard) {
        router.replace({ name: 'login' });
    }

    next();
});

/**
 * Get active restaurant from restaurantId param.
 */
router.beforeEach(async function (to, from, next) {
    if (!to.params.restaurantId) {
        return next();
    }

    const restaurantId = to.params.restaurantId;

    store.activeRestaurant = store.restaurants.find((restaurant) => restaurant.id === restaurantId) || null;

    next();
});

/**
 * Get active location from locationId param.
 */
router.beforeEach(async function (to, from, next) {
    if (!to.params.locationId) {
        if (!store.activeLocation) {
            store.activeLocation = store.activeRestaurant?.locations[0] || null;
        }

        return next();
    }

    const locationId = Number(to.params.locationId);

    store.activeLocation = store.locations.find((location) => location.id == locationId) || null;

    next();
});

router.beforeEach(authGuard);

export default router;
