import axios, { AxiosError, type AxiosRequestConfig, type AxiosResponse } from 'axios';

import ResourceService from './_resource.service';

import type ProductCategory from '@/models/product-category.model';

import type CreateProductRequest from '@/requests/create-product.request';
import type UpdateProductRequest from '@/requests/update-product.request';

export default class ProductCategoriesService extends ResourceService<ProductCategory> {
    public static url = `${import.meta.env.VITE_API_URL}/product-categories`;

    constructor() {
        super(axios, ProductCategoriesService.url);
    }

    /**
     * Gets all products
     *
     * @param config AxiosRequestConfig
     * @returns Product[]
     */
    public static index(config?: AxiosRequestConfig): Promise<ProductCategory[]> {
        return new ResourceService<ProductCategory>(axios, ProductCategoriesService.url).index(config);
    }

    /**
     * Gets a single product with the given id
     *
     * @param id Product id
     * @param config AxiosRequestConfig
     * @returns Product
     */
    public static show(id: number | string, config?: AxiosRequestConfig): Promise<ProductCategory> {
        return new ResourceService<ProductCategory>(axios, ProductCategoriesService.url).show(id, config);
    }

    /**
     * Creates a new product
     *
     * @param createRequest CreateProductRequest
     * @param config AxiosRequestConfig
     * @returns Product
     */
    public static store(createRequest: CreateProductRequest, config?: AxiosRequestConfig): Promise<ProductCategory> {
        return new ResourceService<CreateProductRequest, ProductCategory>(axios, ProductCategoriesService.url).store(
            createRequest,
            config
        );
    }

    /**
     * Update the product-category with the given id.
     * @param id
     * @param data
     * @param config
     * @returns
     */
    public static async update(
        id: number | string,
        data: {
            name: string;
            sides_image?: string;
            drinks_image?: string;
        },
        config?: AxiosRequestConfig
    ): Promise<ProductCategory> {
        const formData = new FormData();

        if (data.name) {
            formData.append('name', JSON.stringify(data.name));
        }

        if (data.sides_image) {
            const response = await fetch(data.sides_image);

            formData.append('sides_image', await response.blob());
        }

        if (data.drinks_image) {
            const response = await fetch(data.drinks_image);

            formData.append('drinks_image', await response.blob());
        }

        formData.append('_method', 'PATCH');

        return await axios
            .post(`${ProductCategoriesService.url}/${id}`, formData, config)
            .then((response: AxiosResponse) => {
                return response.data;
            })
            .catch((error: AxiosError) => {
                const errorData = error.response?.data;

                console.log(errorData);
            });
    }

    /**
     * Deletes a product with the given id
     *
     * @param id Product id
     * @param config AxiosRequestConfig
     * @returns boolean
     */
    public static delete(id: number | string, config?: AxiosRequestConfig): Promise<boolean> {
        return new ResourceService<ProductCategory>(axios, ProductCategoriesService.url).delete(id, config);
    }
}
