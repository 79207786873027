import { createApp } from 'vue';

import App from './App.vue';

const app = createApp(App);

import router from './router';

import axios from 'axios';

import store from '@/store';
import i18n from '@/i18n';

import ClickAway from 'vue3-click-away';

import AuthService from './services/auth.service';

import '@/assets/style.css';
import 'v-calendar/dist/style.css';

import { preload } from './helpers/preload';

import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';

// Initialize Sentry as early as possible
if (!import.meta.env.DEV) {
    Sentry.init({
        app,
        dsn: import.meta.env.VITE_APP_SENTRY_DSN,
        release: 'skaffa-admin',
        integrations: [
            new BrowserTracing({
                tracingOrigins: ['localhost', 'skaffa.fo', '*.skaffa.fo', /^\//],
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            }),
        ],
        sampleRate: 1.0,
        tracesSampleRate: 1.0,
        logErrors: true,
        environment: import.meta.env.MODE,
    });
}

/**
 * Global response interceptor:
 * If an Axios request fails (e.g., due to network issues, server errors, etc.), 
 * the error response is automatically captured and logged to Sentry.
 */
axios.interceptors.response.use((response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    return response;
}, 
    (error) => {
    // Log any error that doesn't fall within the 2xx status code to Sentry
    Sentry.captureException(error);
    // Handle specific error statuses or enrich error data here
    return Promise.reject(error); 
});


/**
 * Add Authorization Bearer Token to each Request.
 */
axios.interceptors.request.use(async (config) => {
    if (!config.headers) {
        return config;
    }

    const token = localStorage.getItem('token');

    config.headers.Authorization = `Bearer ${token}`;

    return config;
});

/**
 * Add 'Accept-Language' to each Request.
 */
axios.interceptors.request.use(async (config) => {
    if (!config.headers) {
        return config;
    }

    config.headers['Accept-Language'] = i18n.global.locale.value;

    return config;
});


(async function () {
    /**
     * Get locale from localStorage & set i18n locale.
     */
    const locale = localStorage.getItem('locale') || 'en';

    i18n.global.locale.value = locale as 'fo' | 'en';

    /**
     * Add Currently Logged In User to Store.
     */
    store.user = { ...(await AuthService.me()), permissions: [], can: () => false };

    await preload();

    app.use(router);
    app.use(i18n);
    app.use(ClickAway);

    // Mount the app
    app.mount('#app');
})();

// Create Global Pusher instance
import Pusher from 'pusher-js';
window.Pusher = Pusher;

// Create Global Echo instance (& connect)
import Echo from 'laravel-echo';

window.Echo = new Echo({
    broadcaster: 'pusher',
    key: import.meta.env.VITE_PUSHER_APP_KEY,
    cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER,
    // Authorize the Socket Connection
    authorizer: (channel: any, options: any) => {
        return {
            authorize: (socketId: any, callback: any) => {
                axios
                    .post(`${import.meta.env.VITE_API_URL}/broadcasting/auth`, {
                        socket_id: socketId,
                        channel_name: channel.name,
                    })
                    .then((response) => {
                        callback(false, response.data);
                    })
                    .catch((error) => {
                        callback(true, error);
                    });
            },
        };
    },
});

// https://pusher.com/docs/channels/using_channels/connection/#available-states

window.Echo.connector.pusher.connection.bind('connected', () => {
    if (store.socketConnectionLost) {
        router.go(0);
    }

    store.socketConnectionLost = false;
});

window.Echo.connector.pusher.connection.bind('unavailable', () => {
    store.socketConnectionLost = true;
});

//Trigger admin build
