import axios, { type AxiosRequestConfig } from 'axios';

import ResourceService from './_resource.service';

import type { Attribute } from '@/models/variant.model';

import type CreateAttributeRequest from '@/requests/create-attribute.request';
import type UpdateAttributeRequest from '@/requests/update-attribute.request';

export default class AttributesService extends ResourceService<Attribute> {
    public static url = `${import.meta.env.VITE_API_URL}/attributes`;

    constructor() {
        super(axios, AttributesService.url);
    }

    /**
     * Gets all attributes
     *
     * @param config AxiosRequestConfig
     * @returns Attribute[]
     */
    public static index(config?: AxiosRequestConfig): Promise<Attribute[]> {
        return new ResourceService<Attribute>(axios, AttributesService.url).index(config);
    }

    /**
     * Gets a single attribute with the given id
     *
     * @param id Attribute id
     * @param config AxiosRequestConfig
     * @returns Attribute
     */
    public static show(id: number | string, config?: AxiosRequestConfig): Promise<Attribute> {
        return new ResourceService<Attribute>(axios, AttributesService.url).show(id, config);
    }

    /**
     * Creates a new attribute
     *
     * @param createRequest CreateAttributeRequest
     * @param config AxiosRequestConfig<any>
     * @returns Attribute
     */
    public static store(createRequest: CreateAttributeRequest, config?: AxiosRequestConfig): Promise<Attribute> {
        return new ResourceService<CreateAttributeRequest, Attribute>(axios, AttributesService.url).store(
            createRequest,
            config
        );
    }

    /**
     * Updates a attribute with the given id
     *
     * @param id Attribute id
     * @param updateRequest UpdateAttributeRequest
     * @param config AxiosRequestConfig<any>
     * @returns Attribute
     */
    public static update(
        id: number | string,
        updateRequest: UpdateAttributeRequest,
        config?: AxiosRequestConfig
    ): Promise<Attribute> {
        return new ResourceService<UpdateAttributeRequest, Attribute>(axios, AttributesService.url).update(
            id,
            updateRequest,
            config
        );
    }

    /**
     * Deletes an attribute with the given id
     *
     * @param id Attribute id
     * @param config AxiosRequestConfig<any>
     * @returns boolean
     */
    public static delete(id: number | string, config?: AxiosRequestConfig): Promise<boolean> {
        return new ResourceService<boolean>(axios, AttributesService.url).delete(id, config);
    }
}
