<script setup lang="ts">
import { computed, watch } from 'vue';

import store from '@/store';

import { icons } from '@/helpers/icons';

/**
 * Data
 */
let timeout = 0;

/**
 * Is active?
 */
const active = computed(() => {
    return !!store.error.active;
});

/**
 *
 */
watch(active, (active) => {
    clearTimeout(timeout);

    if (!active) {
        return;
    }

    timeout = window.setTimeout(() => {
        store.error.active = false;
    }, 5000);
});

/**
 * Close the Error.
 */
function close() {
    store.error.active = false;
}
</script>

<template>
    <Teleport to="body">
        <div
            class="fixed bottom-0 left-0 z-50 w-full max-w-md px-4 pb-8 transition duration-300"
            :class="{ 'pointer-events-none opacity-0': !active }"
        >
            <div
                class="relative flex items-center space-x-4 rounded bg-dark-yellow-100 p-6 text-sm text-dark-yellow-700 transition"
                :class="{
                    'translate-y-24': !active,
                    '!bg-green-100 !text-green-600/80 shadow': store.error?.type == 'success',
                }"
            >
                <!-- Icon -->
                <template v-if="store.error?.type == 'success'">
                    <div
                        v-html="icons.checkmark"
                        class="h-3 w-4 flex-shrink-0"
                    ></div>
                </template>

                <template v-else>
                    <div
                        v-html="icons.warning"
                        class="flex-shrink-0"
                    ></div>
                </template>

                <!-- Text -->
                <div class="pr-2">{{ store.error?.text }}</div>

                <!-- Close Button -->
                <a
                    href="#"
                    @click.prevent="close"
                    class="absolute top-2 right-2 grid h-6 w-6 place-items-center rounded-full transition hover:bg-black/5"
                >
                    <div v-html="icons.close"></div>
                </a>
            </div>
        </div>
    </Teleport>
</template>
