import { reactive } from 'vue';

import arrowRight from '@/assets/icons/arrow-right.svg?raw';
import arrowDownCircle from '@/assets/icons/arrow-down-circle.svg?raw';
import back from '@/assets/icons/back.svg?raw';
import calendar from '@/assets/icons/calendar.svg?raw';
import car from '@/assets/icons/car.svg?raw';
import checkmark from '@/assets/icons/checkmark.svg?raw';
import chef from '@/assets/icons/chef.svg?raw';
import chevron from '@/assets/icons/chevron.svg?raw';
import close from '@/assets/icons/close.svg?raw';
import danger from '@/assets/icons/danger.svg?raw';
import document from '@/assets/icons/document.svg?raw';
import documentCopy from '@/assets/icons/document-copy.svg?raw';
import download from '@/assets/icons/download.svg?raw';
import drag from '@/assets/icons/drag.svg?raw';
import en from '@/assets/icons/en.svg?raw';
import eyeOff from '@/assets/icons/eye-off.svg?raw';
import filePDF from '@/assets/icons/file-pdf.svg?raw';
import fileXLSX from '@/assets/icons/file-xlsx.svg?raw';
import fo from '@/assets/icons/fo.svg?raw';
import group from '@/assets/icons/group.svg?raw';
import lock from '@/assets/icons/lock.svg?raw';
import logo from '@/assets/icons/logo.svg?raw';
import maximize from '@/assets/icons/maximize.svg?raw';
import minus from '@/assets/icons/minus.svg?raw';
import menu from '@/assets/icons/menu.svg?raw';
import padlock from '@/assets/icons/padlock.svg?raw';
import pdf from '@/assets/icons/pdf.svg?raw';
import pen from '@/assets/icons/pen.svg?raw';
import percent from '@/assets/icons/percent.svg?raw';
import plusSmall from '@/assets/icons/plus-small.svg?raw';
import plus from '@/assets/icons/plus.svg?raw';
import pin from '@/assets/icons/pin.svg?raw';
import popper from '@/assets/icons/popper.svg?raw';
import refresh from '@/assets/icons/refresh.svg?raw';
import search from '@/assets/icons/search.svg?raw';
import settings from '@/assets/icons/settings.svg?raw';
import taco from '@/assets/icons/taco.svg?raw';
import trash from '@/assets/icons/trash.svg?raw';
import user from '@/assets/icons/user.svg?raw';
import warning from '@/assets/icons/warning.svg?raw';

export const icons = reactive({
    arrowRight,
    arrowDownCircle,
    back,
    calendar,
    car,
    checkmark,
    chef,
    close,
    danger,
    document,
    documentCopy,
    download,
    chevron,
    drag,
    en,
    eyeOff,
    filePDF,
    fileXLSX,
    fo,
    group,
    lock,
    logo,
    maximize,
    minus,
    padlock,
    menu,
    pdf,
    pen,
    pin,
    percent,
    plusSmall,
    plus,
    popper,
    refresh,
    search,
    settings,
    taco,
    trash,
    user,
    warning,
});
