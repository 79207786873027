<script setup lang="ts">
import { watch } from 'vue';

import { RouterView } from 'vue-router';

import store from '@/store';

import { EventBus } from './helpers/event-bus';

import TheSidebar from '@/components/TheSidebar.vue';
import ThePopup from '@/components/ThePopup.vue';
import TheError from '@/components/TheError.vue';
import TheSuccess from '@/components/TheSuccess.vue';
import TheModals from './components/TheModals.vue';

// Add 'store.activeRestaurant' to localStorage.
watch(
    () => store.activeRestaurant,
    (activeRestaurant) => {
        if (!activeRestaurant) {
            return;
        }

        localStorage.setItem('activeRestaurantId', JSON.stringify(activeRestaurant.id));
    }
);

// Initialize the Event Bus

EventBus.init();
</script>

<template>
    <RouterView v-slot="{ Component }">
        <component :is="Component" />
    </RouterView>

    <!-- The Sidabar -->
    <TheSidebar />

    <!-- The Popup -->
    <ThePopup />

    <!-- The Error -->
    <TheError />

    <TheSuccess />
    <TheModals />
</template>
