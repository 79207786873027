import axios, { type AxiosRequestConfig } from 'axios';

import ResourceService from './_resource.service';

import type User from '@/models/user.model';

import type CreateUserRequest from '@/requests/create-user.request';
import type UpdateUserRequest from '@/requests/update-user.request';

export default class UsersService extends ResourceService<User> {
    public static url: string = `${import.meta.env.VITE_API_URL}/users`;

    constructor() {
        super(axios, UsersService.url);
    }

    /**
     * Gets all users
     *
     * @param config AxiosRequestConfig
     * @returns User[]
     */
    public static index(config?: AxiosRequestConfig): Promise<User[]> {
        return new ResourceService<User>(axios, UsersService.url).index(config);
    }

    /**
     * Gets a single user with the given id
     *
     * @param id User id
     * @param config AxiosRequestConfig
     * @returns User
     */
    public static show(id: number | string, config?: AxiosRequestConfig): Promise<User> {
        return new ResourceService<User>(axios, UsersService.url).show(id, config);
    }

    /**
     * Creates a new user
     *
     * @param createRequest CreateUserRequest
     * @param config AxiosRequestConfig
     * @returns User
     */
    public static store(createRequest: CreateUserRequest, config?: AxiosRequestConfig): Promise<User> {
        return new ResourceService<CreateUserRequest, User>(axios, UsersService.url).store(createRequest, config);
    }

    /**
     * Updates a user with the given id
     *
     * @param id User id
     * @param updateRequest UpdateUserRequest
     * @param config AxiosRequestConfig
     * @returns User
     */
    public static update(id: string, updateRequest: UpdateUserRequest, config?: AxiosRequestConfig): Promise<User> {
        return new ResourceService<UpdateUserRequest, User>(axios, UsersService.url).update(id, updateRequest, config);
    }

    /**
     * Deletes a user with the given id
     *
     * @param id User id
     * @param config AxiosRequestConfig
     * @returns boolean
     */
    public static delete(id: string | string, config?: AxiosRequestConfig): Promise<boolean> {
        return new ResourceService<User>(axios, UsersService.url).delete(id, config);
    }
}
